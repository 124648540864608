import React, { Fragment, useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from '../pages/home';

import PaginaLogin from '../pages/login';
import WizardPage from '../pages/wizard/index';
import { NavegacaoProvider } from '../contexts/navegacao';
import { MenuProvider } from '../contexts/menu';
import { DefaultLayout } from '../layouts/DefaultLayout';

import { AuthContext } from '../contexts/auth';

import Carregando from '../pages/carregando';

import authRequired from './authRequired.json';
import plataformaPages from './plataformaPages.json';
import Top10 from '../pages/analiseRelatorios/faturamento/produtosVendidos/top10/relatorioPdf';
import RelatorioPdf from '../pages/analiseRelatorios/cadastrais/fichaTecnica/relatorioPdf';
import RelatorioPdfContasAPagar from '../pages/analiseRelatorios/financas/contasAPagar/relatorioPdfContasAPagar';
import RelatorioPdfContasAReceber from '../pages/analiseRelatorios/financas/contasAReceber/relatorioPdfContasAReceber';
import { RecuperarSenha } from '../pages/recuperacaoSenha';
import RelatorioPdfPisCofins from '../pages/analiseRelatorios/cadastrais/tributacao/piscofins/relatorioPdfPiscofins';

import rotasSemHeader from "./rotasSemHeader.json";
import DetalhesPlano from "../pages/gerenciamentoAssinaturaPlanos/detalhesPlano";
import { RedirectWaymenu } from "../components/RedirectWaymenu";
import { useAuthRoute } from "./hooks/useAuthRoute";

const lazyImportedPagesAuthRequired = authRequired.pages.map((page) => {
  const nivel = page.nivelNecessario
  const Component = React.lazy(() => import(`/src/pages/${page.caminho}`));
  const permissoes = page.permissoes

  return {
    ...page,
    Element: () => {
      return useAuthRoute(Component, permissoes, nivel);
    },
  };
});

//Plataforma cashback
const lazyImportedPagesPlataforma = plataformaPages.pages.map((page) => {
  return {
    ...page,
    Element: React.lazy(() => import(`/src/pages/${page.caminho}`)),
  };
});


const lazyImportedPagesSemHeader = rotasSemHeader.pages.map((page) => {
  return {
    ...page,
    Element: React.lazy(() => import(`/src/pages/${page.caminho}`)),
  };
});

const lazyImportedPages = [
  ...lazyImportedPagesAuthRequired,
  ...lazyImportedPagesSemHeader,
];

const NovoDashboard = React.lazy(() =>
  import('../pages/dashboard/novoDashboard')
);
const ListagemCashback = React.lazy(() => import("../pages/cashback/listagem"));

const RelatorioCashBack = React.lazy(() =>
  import('../pages/cashback/relatorios/relatorio')
);
const CashBackPDF = React.lazy(() =>
  import('../pages/cashback/relatorios/relatorio/relatorio')
);
const CadastroCashBack = React.lazy(() => import('../pages/cashback/cadastro'));
const EdicaoCashback = React.lazy(() => import('../pages/cashback/edicao'));
const ConfiguracoesCashback = React.lazy(() =>
  import('../pages/cashback/configuracoes')
);
const RelatorioTop10 = React.lazy(() =>
  import(
    '../pages/analiseRelatorios/faturamento/produtosVendidos/top10/relatorioPdf'
  )
);
const RelatorioEstoqueEmFalta = React.lazy(() =>
  import('../pages/analiseRelatorios/estoque/emFalta/relatorioPdf')
);
const RelatorioEstoqueHistoricoMovimentacao = React.lazy(() =>
  import(
    '../pages/analiseRelatorios/estoque/historicoMovimentacoes/relatorioPdf'
  )
);
const RelatorioItensCancelados = React.lazy(() =>
  import(
    '../pages/analiseRelatorios/faturamento/cancelamentos/itensCancelados/relatorioPdf'
  )
);
const EspelhoVendaPdf = React.lazy(() =>
  import('../pages/consultasRapidas/atendimentos/pdfFicha')
);

const RoutesApp = () => {
  const { logado, loading, pagamentoNecessario, usuarioMaster } =
    useContext(AuthContext);
  // const logado = true;
  // const loading = false;

  const sessao = JSON.parse(localStorage.getItem('sessao'));
  const contratosAtivos = sessao
    ? sessao?.contratoWaychefAtualDTO?.listaItens
    : null;
  const permissoesWaybe = sessao
    ? sessao?.contratoWaybeAtualDTO?.permissoesWaybe
    : null;
  const tipoPerfilUsuarioMaster = sessao
    ? sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.tipoPerfilUsuario ===
    'MASTER'
    : null;

  const temPrivilegioCashback = sessao
    ? sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.listaPrivilegios
      ?.PARAM_REL_CASHBACK
    : null;
  const temContratoCashback = permissoesWaybe?.includes('CONTROLE_CASHBACK');

  return (
    <NavegacaoProvider>
      <BrowserRouter>
        <MenuProvider>
          <Fragment>
            <Routes>
              {loading ? (
                <Route path="*" element={<Carregando />} />
              ) : !logado ? (
                <Fragment>
                  <Route path="*" element={<Navigate to="/login" />} />
                  <Route path="login" element={<PaginaLogin />} />
                  <Route
                    path="cadastro/:idIntegrador"
                    element={<WizardPage />}
                  />
                  <Route
                    path="cadastro/usuario/recuperacao"
                    element={<RecuperarSenha />}
                  />

                  {lazyImportedPagesPlataforma.map(({ Element, ...page }) => {
                    return (
                      <Route
                        path={page.url}
                        element={
                          <React.Suspense fallback={<Carregando />}>
                            <Element />
                          </React.Suspense>
                        }
                      />
                    );
                  })}
                </Fragment>
              ) : pagamentoNecessario ? (
                usuarioMaster ? (
                  <Fragment>
                    <Route path="*" element={<DefaultLayout />}>
                      <Route
                        path="*"
                        element={<Navigate to="/gerenciar-plano" />}
                      />
                      <Route
                        path="gerenciar-plano"
                        element={<DetalhesPlano />}
                      />
                    </Route>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Route path="*" element={<DefaultLayout />}>
                      <Route path="*" element={<Navigate to="/home" />} />
                      <Route path="home" element={<Home />} />
                    </Route>
                  </Fragment>
                )
              ) : (
                <Fragment>
                  <Route path="login" element={<Navigate to="/" />} />
                  <Route path="/" element={<DefaultLayout />}>
                    {lazyImportedPages.map(({ Element, ...page }) => {
                      return (
                        <Route
                          key={'rota' + page.url}
                          path={page.url}
                          element={
                            <React.Suspense fallback={<Carregando />}>
                              <Element />
                            </React.Suspense>
                          }
                        />
                      );
                    })}

                    <Route
                      path="/"
                      element={
                        <React.Suspense fallback={<Carregando />}>
                          <Home />
                        </React.Suspense>
                      }
                    />

                    {lazyImportedPages.map(({ Element, ...page }) => {
                      return (
                        <Route
                          key={'rotaHeader' + page.url}
                          path={page.url}
                          element={
                            <React.Suspense fallback={<Carregando />}>
                              <Element />
                            </React.Suspense>
                          }
                        />
                      );
                    })}

                    <Route
                      path="dashboard/novo"
                      element={
                        <React.Suspense fallback={<Carregando />}>
                          <NovoDashboard />
                        </React.Suspense>
                      }
                    />

                    {temContratoCashback &&
                      (temPrivilegioCashback || tipoPerfilUsuarioMaster) && (
                        <Route
                          path="relatorios/cashback"
                          element={
                            <React.Suspense fallback={<Carregando />}>
                              <RelatorioCashBack />
                            </React.Suspense>
                          }
                        />
                      )}

                    <Route
                      path="fidelidade/cashback"
                      element={
                        <React.Suspense fallback={<Carregando />}>
                          <ListagemCashback />
                        </React.Suspense>
                      }
                    />

                    <Route
                      path="fidelidade/cashback/configuracoes"
                      element={
                        <React.Suspense fallback={<Carregando />}>
                          <ConfiguracoesCashback />
                        </React.Suspense>
                      }
                    />

                    <Route
                      path="fidelidade/cashback/novo"
                      element={
                        <React.Suspense fallback={<Carregando />}>
                          <CadastroCashBack />
                        </React.Suspense>
                      }
                    />

                    <Route
                      path="fidelidade/cashback/:id"
                      element={
                        <React.Suspense fallback={<Carregando />}>
                          <EdicaoCashback />
                        </React.Suspense>
                      }
                    />
                  </Route>

                  <Route
                    path="relatorios/cashback/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <CashBackPDF />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="analise-relatorios/faturamento/cancelamentos/itens-cancelados/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioItensCancelados />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path={
                      '/analise-relatorios/produtos-vendidos/analise-relatorios/produtos-vendidos/top10/pdf'
                    }
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioTop10 />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/consultas-rapidas/atendimentos/espelho-venda"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <EspelhoVendaPdf />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/analise-relatorios/estoque/em-falta/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioEstoqueEmFalta />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="analise-relatorios/estoque/historico-movimentacoes/pdf"
                    element={
                      <React.Suspense>
                        <RelatorioEstoqueHistoricoMovimentacao />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="fidelidade/cashback/cadastro"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <CadastroCashBack />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/produtos-vendidos/analise-relatorios/produtos-vendidos/top10/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <Top10 />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="analiseRelatorios/cadastrais/fichaTecnica.pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdf />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/financas/contas-a-pagar/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdfContasAPagar />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/financas/contas-a-receber/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdfContasAReceber />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/cadastrais/tributacao/piscofins/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdfPisCofins />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="relatorios/cashback/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <CashBackPDF />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="analise-relatorios/faturamento/cancelamentos/itens-cancelados/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioItensCancelados />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path={
                      '/analise-relatorios/produtos-vendidos/analise-relatorios/produtos-vendidos/top10/pdf'
                    }
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioTop10 />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="/analise-relatorios/estoque/em-falta/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioEstoqueEmFalta />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="analise-relatorios/estoque/historico-movimentacoes/pdf"
                    element={
                      <React.Suspense>
                        <RelatorioEstoqueHistoricoMovimentacao />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="fidelidade/cashback/cadastro"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <CadastroCashBack />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/produtos-vendidos/analise-relatorios/produtos-vendidos/top10/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <Top10 />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="analiseRelatorios/cadastrais/fichaTecnica.pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdf />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/financas/contas-a-pagar/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdfContasAPagar />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/financas/contas-a-receber/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdfContasAReceber />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="/analise-relatorios/cadastrais/tributacao/piscofins/pdf"
                    element={
                      <React.Suspense fallback={<Carregando />}>
                        <RelatorioPdfPisCofins />
                      </React.Suspense>
                    }
                  />

                  <Route
                    path="waymenu/redirect/:tipo"
                    element={<RedirectWaymenu />}
                  />

                  {lazyImportedPages.map(({ Element, ...page }) => {
                    return (
                      <Route
                        path={page.url}
                        element={
                          <React.Suspense fallback={<Carregando />}>
                            <Element />
                          </React.Suspense>
                        }
                      />
                    );
                  })}
                </Fragment>
              )}
            </Routes>
          </Fragment>
        </MenuProvider>
      </BrowserRouter>
    </NavegacaoProvider>
  );
};

export default RoutesApp;
