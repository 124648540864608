import React from "react";
import { useTheme } from "@emotion/react";
import {
  MenuItemFilhoContainer,
  PopupCustomizadoPerfil,
  OpcaoItem,
  TextoItem,
  Linha,
  TextoPerfil,
  Coluna,
} from "../Header/styles";
import { ReactComponent as IconeConta } from "../../assets/icons/account_circle.svg";
import { useState, useEffect } from "react";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { useAuth } from "../../contexts/auth";

import { ReactComponent as IconeContaCheio } from "../../assets/icons/account_circle_filled.svg";
import { ReactComponent as IconeSair } from "../../assets/icons/exit_to_app.svg";
import { useApi } from "../../contexts/api";
import { useMenu } from "../../contexts/menu";

import {
  faBuilding,
  faCashRegister,
  faMoneyBillWave,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useNavigate } from "react-router-dom";
import { useLoader } from "../../contexts/loader";
import { realizarLogoff as realizarLogoffCashback } from "../../pages/plataformaCashback/api";
import { usePermissoesLocalStorage } from "../../utils/usePermissoesLocalStorage";


export const Perfil = React.forwardRef(
  ({ nome, style, onClick, largura }, ref) => {
    const theme = useTheme();
    return (
      <Linha style={style} onClick={onClick} ref={ref}>
        {largura > 1000 ? <TextoPerfil>Olá, {nome}</TextoPerfil> : ""}
        <IconeConta style={{ fontSize: 32, fill: theme.cores.textoSimples }} />
      </Linha>
    );
  }
);

const tipoEmpresa = JSON.parse(localStorage.getItem("sessao"))?.empresaDTO
  ?.tipoEmpresa;

export const PerfilPopup = ({ style, setAberto, plataformaCashback }) => {
  const { abrirCarregamento } = useLoader();
  const { realizarLogoff } = useApi();
  const desconectar = () => {
    setAberto(false);
    abrirCarregamento();
    plataformaCashback ? realizarLogoffCashback() : realizarLogoff();
  };

  return (
    <>
      <MenuItemFilhoContainer
        className="item-ver-perfil"
        style={{
          padding: "0px 4px",
          gap: "8px",
        }}
      >
        {!plataformaCashback && (
          <>
            <IconeContaCheio />
            <p>Ver Perfil</p>
          </>
        )}
      </MenuItemFilhoContainer>
      <MenuItemFilhoContainer
        style={{
          padding: "0px 4px",
          gap: "8px",
        }}
        onClick={desconectar}
      >
        <IconeSair />
        <p>Sair</p>
      </MenuItemFilhoContainer>
    </>
  );
};

const BotaoPerfil = ({ style }) => {
  const [aberto, setAberto] = useState(false);
  const { largura } = useTamanhoTela();
  const { realizarLogoff } = useApi();
  const navigate = useNavigate();
  const { verificarPermissao } = usePermissoesLocalStorage()
  const [permissoesDaTela, setPermissoesDaTela] = useState(verificarPermissao("PESSOA_MINHA_EMPRESA"))

  const { abrirCarregamento } = useLoader();

  const theme = useTheme();

  const { dadosUsuario } = useMenu();

  const validarNomeUsuario = (usuario) => {
    try {
      const nome = usuario.nome.split(" ");
      return nome[0];
    } catch (error) {
      return "Usuário";
    }
  };

  const irParaPagamento = () => {
    navigate("/gerenciar-plano");
    setAberto(false);
  };
  const irParaDadosEmpresa = () => {
    navigate("/dados-empresa");
    setAberto(false);
  };

  const desconectar = () => {
    setAberto(false);
    abrirCarregamento();
    realizarLogoff();
  };

  useEffect(() => {
    setAberto(false);
  }, [largura]);

  return (
    <PopupCustomizadoPerfil
      trigger={
        <Perfil
          largura={largura}
          nome={validarNomeUsuario(dadosUsuario)}
          onClick={() => setAberto(!aberto)}
          style={style}
        />
      }
      onClose={() => setAberto(false)}
      onOpen={() => setAberto(true)}
      open={aberto}
      position="bottom center"
      arrowStyle={{ visibility: "hidden" }}
      offsetY={12}
    >
      {tipoEmpresa !== "PLATAFORMA" && tipoEmpresa !== "INTEGRADOR" && (
        <OpcaoItem onClick={irParaPagamento} efeito={true}>
          <FontAwesomeIcon
            style={{
              marginRight: "6px",
              fontSize: "16px",
              color: theme.cores.neutral60,
            }}
            icon={faMoneyBillWave}
          />
          <TextoItem>Pagamento</TextoItem>
        </OpcaoItem>
      )}
      {permissoesDaTela.consultar && <OpcaoItem onClick={irParaDadosEmpresa} efeito={true}>
        <FontAwesomeIcon
          style={{
            marginRight: "6px",
            fontSize: "16px",
            color: theme.cores.neutral60,
          }}
          icon={faBuilding}
        />
        <TextoItem>Dados da empresa</TextoItem>
      </OpcaoItem>}
      <OpcaoItem onClick={desconectar} efeito={true}>
        <FontAwesomeIcon
          style={{
            marginRight: "6px",
            fontSize: "16px",
            color: theme.cores.neutral60,
          }}
          icon={faRightFromBracket}
        />
        <TextoItem>Desconectar</TextoItem>
      </OpcaoItem>

    </PopupCustomizadoPerfil>
  );
};

export default BotaoPerfil;
