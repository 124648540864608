import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Linha,
  Background,
  ContainerFormulario,
  ImagemWaybe,
  TextoAviso,
  TextoTitulo,
  ContainerInputs,
  ContainerLoading,
  SwitchEstilizado,
} from './styles';
import ImgBackgroundLogin from '../../../assets/images/login_background.png';
import ImgWaybe from '../../../assets/images/waybe_logo.png';
import { CampoDeTexto } from '../../../components/CampoInput';
import { BotaoAzulPreenchido } from '../../../components/Botao';

import 'react-spring-bottom-sheet/dist/style.css';
import { Link } from 'react-router-dom';
import FormatarCPFCNPJTempoReal from '../../../utils/formatarCPFCNPJTempoReal';
import { validarEmail } from '../../../utils/validarEmail';
import { toast } from 'react-toastify';
import { validarCPF } from '../../../utils/validarCPF';
import { validarCNPJ } from '../../../utils/validarCNPJ';
import Carregando from '../../carregando';
import Switch from '../../../components/Switch';
import { TemaCustomizadoContext } from '../../../contexts/theme';
import { useTheme } from '@emotion/react';
import useTamanhoTela from '../../../utils/useTamanhoTela';

export function PaginaInicial({ setDados, dados, api, token }) {
  const [email, setEmail] = useState('');
  const [erroEmail, setErroEmail] = useState(false);
  const [cpfecnpj, setCpfecnpj] = useState('');
  const [erroCpfecnpj, setErroCpfcnpj] = useState(false);
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const { mudarTema } = useContext(TemaCustomizadoContext);
  async function validarEmaileCPFouCNPJ(inscFederal, email) {
    try {
      if (inscFederal?.length === 0 || !inscFederal) {
        toast.error('Insira um CPF/CNPJ válido!');
        setErroCpfcnpj(true);
        return;
      }
      const inscFederalAjustada = inscFederal?.replace(/[^0-9]/g, '');
      const res = await api({
        method: 'get',
        url: `/configuracoes-lojas/wizard/validar-empresa/${inscFederalAjustada}/${email}`,
      });

      if (res.status === 200) {
        setErroCpfcnpj(false);
        return true;
      } else {
        toast.error('Insira um CPF/CNPJ válido!');
        setErroCpfcnpj(true);
        return false;
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.message);
      }
      setErroCpfcnpj(true);
      return false;
    }
  }

  async function verificarErrosCampos() {
    let camposOK = true;
    if (!validarEmail(email)) {
      toast.error('Insira um e-mail válido!');
      setErroEmail(true);
      camposOK = false;
    } else {
      setErroEmail(false);
    }

    if (cpfecnpj && cpfecnpj.length < 15 && !validarCPF(cpfecnpj)) {
      toast.error('Insira um CPF/CNPJ válido!');
      setErroCpfcnpj(true);
      camposOK = false;
    } else {
      setErroCpfcnpj(false);
    }
    if (cpfecnpj && cpfecnpj.length > 15 && !validarCNPJ(cpfecnpj)) {
      toast.error('Insira um CPF/CNPJ válido!');
      setErroCpfcnpj(true);
      camposOK = false;
    } else {
      setErroCpfcnpj(false);
    }

    const cadastroOk = await validarEmaileCPFouCNPJ(cpfecnpj, email);
    return camposOK && cadastroOk ? true : false;
  }

  return (
    <Container>
      <Background url={ImgBackgroundLogin} />
      <ContainerFormulario>
        <Switch
          onClick={() => {
            mudarTema();
          }}
          checked={!theme.temaDarkAtivo}
          valor={!theme.temaDarkAtivo}
          style={{ position: 'absolute', top: 10, right: 0 }}
          mudarTema
        />
        <TextoTitulo>Boas Vindas ao</TextoTitulo>
        <ImagemWaybe url={ImgWaybe} />
        <TextoAviso>Vamos começar!</TextoAviso>
        <TextoAviso>Primeiro informe seu CNPJ ou CPF</TextoAviso>

        {token ? (
          <ContainerInputs>
            <CampoDeTexto
              titulo={'CNPJ ou CPF'}
              defaultValue={''}
              onChange={(e) => {
                if (e.target.value.length <= 18) {
                  setCpfecnpj(FormatarCPFCNPJTempoReal(e.target.value));
                }
              }}
              value={cpfecnpj}
              error={erroCpfecnpj}
              required
            />
            <CampoDeTexto
              titulo={'E-mail'}
              placeholder={'exemplo@mail.com'}
              defaultValue={''}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={erroEmail}
              required
            />
            <Linha>
              <BotaoAzulPreenchido
                texto="Próximo"
                onClick={() => {
                  const verificarError = async () => {
                    if (await verificarErrosCampos()) {
                      setDados({
                        cpfecnpj,
                        email,
                        camposPreenchidos: true,
                      });
                    }
                  };
                  verificarError();
                }}
              />
            </Linha>
          </ContainerInputs>
        ) : (
          <ContainerLoading>
            <Carregando style={{ minHeight: 150 }} />
          </ContainerLoading>
        )}

        <TextoAviso>
          Caso já possua uma conta <Link to={'/login'}> faça o login</Link>
        </TextoAviso>
      </ContainerFormulario>
    </Container>
  );
}

export default PaginaInicial;
