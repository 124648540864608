export const formatarCelularTempoReal = (value) => {

    try {
        let r = value.target.value.replace(/\D/g, "");
        r = r.replace(/^0/, "");

        if (r.length > 10) {
            // Format cell phone numbers: (17) 99629-9200
            r = r.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 6) {
            // Format landline numbers: (17) 3245-3223
            r = r.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
            // Partial format for numbers with area code and some digits
            r = r.replace(/^(\d{2})(\d{0,4})/, "($1) $2");
        } else if (value.target.value.trim() !== "") {
            // Partial format for area code
            r = r.replace(/^(\d*)/, "($1");
        }
        return r === '(' ? '' : r;
    } catch (error) {
        console.error(error);
        return value;
    }
}