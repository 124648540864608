const FormatarCPFCNPJTempoReal = (value) => {
  if (!value) {
    return null;
  }
  let inputValue = value.replace(/\D/g, '');

  if (inputValue.length <= 11) {
    // CPF Formatting
    inputValue = inputValue.replace(/(\d{3})(\d)/, '$1.$2');
    inputValue = inputValue.replace(/(\d{3})(\d)/, '$1.$2');
    inputValue = inputValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    // CNPJ Formatting
    inputValue = inputValue.replace(/^(\d{2})(\d)/, '$1.$2');
    inputValue = inputValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    inputValue = inputValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
    inputValue = inputValue.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }

  return inputValue;
};

export default FormatarCPFCNPJTempoReal;
