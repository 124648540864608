import { useEffect, useState } from 'react';
import { PaginaInicial } from './paginaInicial';
import Cadastro from './cadastro';
import axios from 'axios';
import { baseURL, useApi } from '../../contexts/api';
import Carregando from '../carregando';

const WizardPage = () => {
  const [dadosPaginaInicial, setPaginaInicial] = useState({
    cpfecnpj: null,
    email: null,
    camposPreenchidos: false,
  });

  const [token, setToken] = useState(null);
  const { post } = useApi();

  let infoAPI = {
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const api = axios.create(infoAPI);

  async function buscarSessao() {
    try {
      const res = await post('usuario-sessao/client', {
        'tipoClient': 'WAYMENU',
        'clientKey': '25A5F6363E287EB13C087A4C9113775F',
        'versaoClientDTO': {
          'versaoPrincipal': 3,
          'versaoRelease': 1,
          'versaoCorrecao': '00',
        },
      });

      setToken(res.data.token);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    buscarSessao();
  }, []);

  return (
    <>
      {dadosPaginaInicial.camposPreenchidos ? (
        <Cadastro
          api={api}
          setDados={setPaginaInicial}
          dados={dadosPaginaInicial}
        />
      ) : (
        <PaginaInicial
          setDados={setPaginaInicial}
          dados={dadosPaginaInicial}
          api={api}
          token={token}
        />
      )}
    </>
  );
};

export default WizardPage;
