const PERMISSIONS = {
    CONSULTAR: 'CONSULTAR_PERMITIR',
    INSERIR: 'INSERIR',
    ALTERAR: 'ALTERAR',
    EXCLUIR: 'EXCLUIR',
};

export function usePermissoesLocalStorage() {
    const sessao = JSON.parse(localStorage.getItem("sessao")) || {};

    const usuarioPerfil = sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO || {};
    const listaPrivilegios = usuarioPerfil.listaPrivilegios || {};
    const tipoPerfilUsuario = usuarioPerfil.tipoPerfilUsuario || false;

    function verificarPermissao(permissao) {
        const nivel = listaPrivilegios?.[permissao];

        if (tipoPerfilUsuario === "MASTER") {
            return {
                consultar: true,
                inserir: true,
                alterar: true,
                excluir: true,
            };
        }

        switch (nivel) {
            case PERMISSIONS.CONSULTAR:
                return {
                    consultar: true,
                    inserir: false,
                    alterar: false,
                    excluir: false,
                };
            case PERMISSIONS.INSERIR:
                return {
                    consultar: true,
                    inserir: true,
                    alterar: false,
                    excluir: false,
                };
            case PERMISSIONS.ALTERAR:
                return {
                    consultar: true,
                    inserir: true,
                    alterar: true,
                    excluir: false,
                };
            case PERMISSIONS.EXCLUIR:
                return {
                    consultar: true,
                    inserir: true,
                    alterar: true,
                    excluir: true,
                };
            default:
                // Default fallback if no permission level is found
                return {
                    consultar: false,
                    inserir: false,
                    alterar: false,
                    excluir: false,
                };
        }
    }

    return { verificarPermissao };
}
