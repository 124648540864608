import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";


export function useAuthRoute(WrappedComponent, requiredPermission, nivelExigido) {
  const sessao = JSON.parse(localStorage.getItem("sessao"));


  const { listaPrivilegios = {}, tipoPerfilUsuario = "" } = sessao.usuarioLogadoDTO.usuarioPerfilEmpresaDTO

  const nivelUsuario = listaPrivilegios[requiredPermission]
  const listaPrivilegiosKeys = Object.keys(listaPrivilegios);

  function verificarNivel(nivelExigido) {
    let nivelAtual = 0;

    if (nivelExigido === "CONSULTAR_PERMITIR") {
      nivelAtual = 1
    }
    if (nivelExigido === "ALTERAR") {
      nivelAtual = 2;
    }
    if (nivelExigido === "INSERIR") {
      nivelAtual = 3
    }
    if (nivelExigido === "EXCLUIR") {
      nivelAtual = 4
    }

    if (tipoPerfilUsuario === "MASTER") {
      nivelAtual = 4
    }
    return nivelAtual
  }
  const nivelExigidoNumerico = nivelExigido ? verificarNivel(nivelExigido) : 0
  const nivelAtualNumerico = nivelUsuario ? verificarNivel(nivelUsuario) : verificarNivel(tipoPerfilUsuario)


  const navigate = useNavigate();

  useEffect(() => {
    if (!requiredPermission && requiredPermission?.some(permission => listaPrivilegiosKeys.includes(permission)) || nivelAtualNumerico < nivelExigidoNumerico) {
      toast.error("Você não possui permissão para acessar essa página!")
      navigate('/');
      // return null;
    }
  }, [])



  return <WrappedComponent />;
}
