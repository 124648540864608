import {
  ContainerTabelaGlobal,
  StyledTabela,
  TextoFiltroSemResultado,
} from "./styles";


import { useTheme } from "@emotion/react";
import tornarSlider from "../../utils/tornarSlider";
import { HeaderTabela } from "./HeaderTabela";
import { useEffect, useRef } from "react";

import { LinhaTabela } from "./LinhaTabela";
import useTamanhoTela from "../../utils/useTamanhoTela";
import Carregando from "../../pages/carregando";

export function TabelaSimples({ dados = undefined, item }) {
  const { largura } = useTamanhoTela();
  const theme = useTheme();
  const refTabelaScroll = useRef();
  console.log({ dados }, { item }, "testess");
  useEffect(() => {
    tornarSlider(refTabelaScroll.current);
  }, [refTabelaScroll]);
  return item ? (
    <>
      <ContainerTabelaGlobal ref={refTabelaScroll}>
        <StyledTabela dados={dados.gridColunaTabelaSecundaria}>
          <thead>
            <HeaderTabela dados={dados} />
          </thead>
          <tbody>
            <LinhaTabela dados={dados} itensTabela={item} />
            {item?.itensTabelaSecundaria &&
              item?.itensTabelaSecundaria?.length === 0 && (
                <TextoFiltroSemResultado>
                  Sem dados para exibir
                </TextoFiltroSemResultado>
              )}
          </tbody>
        </StyledTabela>
      </ContainerTabelaGlobal>
    </>
  ) : (
    <Carregando adaptavel={true} />
  );
}
