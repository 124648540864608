import React, { useContext, useEffect, useState } from 'react';
import { TemaCustomizadoContext } from '../../contexts/theme';
import Switch from '../Switch';
import { ThemeProvider, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import darkMode from '../../assets/icons/dark_mode.svg';
import lightMode from '../../assets/icons/wb_sunny.svg';

import { ReactComponent as IconeConfiguracao } from "../../assets/icons/settings.svg";
import { ReactComponent as IconeMenuMobile } from "../../assets/icons/more_vert.svg";
import { MenuItemFilhoContainer, PopupCustomizado } from "../Header/styles";
import { Perfil, PerfilPopup } from "../BotaoPerfil";
import useTamanhoTela from "../../utils/useTamanhoTela";
import FuncionamentoEmpresa from "../BotaoFuncionamentoEmpresa";

const SwitchEstilizado = styled(Switch)`
  margin: 0;
  span {
    margin: 0;
  }

  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.cores.neutral100};
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      background-color: transparent;
      height: min-content;
      /* display: flex;
            align-items: center;
            justify-content: center; */
      margin-top: 3px;
      content: ${({ theme }) =>
    `url(${theme.temaDarkAtivo ? darkMode : lightMode})`};
    }
  }

  .MuiSwitch-track {
    background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? theme.adaptativo("", "actionDisable")
      : "rgba(144, 205, 255, 1)"} !important;
  }
`;

const MenuConfiguracao = ({ children, texto }) => {
  const theme = useTheme();
  const { mudarTema } = useContext(TemaCustomizadoContext);
  return (
    <MenuItemFilhoContainer>
      {texto === 'Perfil' ? (
        <Perfil
          style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: '10px 0',
          }}
        />
      ) : (
        <>
          {children}
          {texto === 'Tema' ? (
            <SwitchEstilizado
              style={{
                margin: 0,
              }}
              onChange={() => {
                mudarTema();
              }}
              checked={theme.temaDarkAtivo}
              valor={theme.temaDarkAtivo}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </MenuItemFilhoContainer>
  );
};

const IconeConfiguracaoEstilizado = styled(IconeConfiguracao)`
  font-size: 32px;
  fill: ${({ theme }) => theme.cores.brand40neutral100};
  cursor: pointer;
  /* transform: rotate(${({ $aberto }) => ($aberto ? '180deg' : '0deg')}); */
  transition: transform 0.3s ease-in-out;
`;

const Configuracoes = ({ mobile = false, plataformaCashback = false }) => {
  const theme = useTheme();
  const { mudarTema } = useContext(TemaCustomizadoContext);
  const [aberto, setAberto] = useState(false);
  const { largura } = useTamanhoTela();

  useEffect(() => {
    setAberto(false);
  }, [largura]);

  return !mobile ? (
    <SwitchEstilizado
      style={{
        margin: 0,
      }}
      onChange={() => {
        mudarTema();
      }}
      checked={!theme.temaDarkAtivo}
      valor={!theme.temaDarkAtivo}
    />
  ) : (
    <PopupCustomizado
      trigger={
        !mobile ? (
          <IconeConfiguracaoEstilizado
            style={{
              transform: `rotate(${({ aberto }) =>
                aberto ? '180deg' : '0deg'})`,
            }}
          />
        ) : (
          <IconeMenuMobile
            style={{
              fill: theme.cores.brand40neutral100,
              marginTop: 20,
              transform: 'scale(1.2)',
            }}
          />
        )
      }
      onClose={() => setAberto(false)}
      onOpen={() => setAberto(true)}
      open={aberto}
      position="bottom right"
      arrowStyle={{ visibility: 'hidden' }}
      offsetY={12}
    >
      <Perfil
        style={{
          justifyContent: 'center',
        }}
      />

      <MenuItemFilhoContainer
        style={{
          padding: '0px 4px',
          gap: '8px',
        }}
      >
        <SwitchEstilizado
          style={{
            margin: 0,
          }}
          onChange={() => {
            mudarTema();
          }}
          checked={!theme.temaDarkAtivo}
          valor={!theme.temaDarkAtivo}
        />
        <p>Tema</p>
      </MenuItemFilhoContainer>

      <FuncionamentoEmpresa />
      <PerfilPopup
        setAberto={setAberto}
        plataformaCashback={plataformaCashback}
      />
    </PopupCustomizado>
  );
};

export default Configuracoes;
