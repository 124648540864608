export function getURLWaymenu(baseURL) {
  if (!baseURL) {
    return "";
  }
  let urlWaymenu = null;

  if (baseURL.includes("erpdev_react") || baseURL.includes("localhost")) {
    urlWaymenu = "http://waymenudev_react.waybe.com.br:3001";
  } else if (baseURL.includes("erphomologqa_react")) {
    urlWaymenu = "http://waymenuhomologqa_react.waybe.com.br:3002";
  } else if (baseURL.includes("erphomolog_react")) {
    urlWaymenu = "http://waymenuhomolog_react.waybe.com.br:3003";
  } else if (baseURL.includes("erp.waybe")) {
    urlWaymenu = "https://waymenu.waybe.com.br";
  }
  return urlWaymenu;
}
