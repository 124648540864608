import {
  Bloco,
  CardPlanoSelecionado,
  ContainerBotoes,
  ContainerBotoesExcluir,
  ContainerConteudo,
  ContainerDoisInputs,
  ContainerEtapas,
  ContainerGlobal,
  ContainerInputs,
  ContainerTexto,
  ContainerTextoItemSelecionado,
  ContainerTitulo,
  ContainerTituloTooltip,
  Conteudo,
  ConteudoPopUp,
  Contrato,
  CustomTooltip,
  Especificacoes,
  EspecificacoesSenha,
  Etapa,
  HeaderPopup,
  ImagemWaybe,
  Separador,
  StyledAutocomplete,
  StyledHeader,
  TextoItemSelecionado,
  TextoItemSelecionadoExcluir,
  TextoTitulo,
  TextoTituloModal,
  WrapperContratos,
  XIcon,
} from "./styles";
import ImgWaybe from "../../../assets/images/waybe_logo.png";
import Switch from "../../../components/Switch";
import { useContext, useEffect, useRef, useState } from "react";
import { TemaCustomizadoContext } from "../../../contexts/theme";
import { useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import ImgCadastroFinalizadoLight from "../../../assets/images/cadastroFinalizadoLight.svg";
import ImgCadastroFinalizadoDark from "../../../assets/images/cadastroFinalizadoDark.svg";
import {
  BotaoAzulPreenchido,
  BotaoAzulSemFundo,
  BotaoAzulVazado,
  BotaoVerdePreenchido,
  BotaoVermelhoVazado,
} from "../../../components/Botao";
import {
  CampoDeDataSemHora,
  CampoDeSelecao,
  CampoDeSenha,
  CampoDeTexto,
} from "../../../components/CampoInput";
import { Controller, useForm } from "react-hook-form";
import { date, number, object, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatarCelularTempoReal } from "../../../utils/formatarCelularTempoReal";
import { validarEmail } from "../../../utils/validarEmail";
import { CircularProgress, TextField } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { formatarCPFTempoReal } from "../../../utils/formatarCPFTempoReal";
import { validarCPF } from "../../../utils/validarCPF";
import GerarModalResponsivo from "../../../components/GerarModalResponsivo";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { formatarCEP } from "../../../utils/formatarCEP";
import formatarReal from "../../../utils/formatarReal";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../../contexts/loader";
import useRecaptchaV3 from "../../../hooks/reCaptchaV3";
import { Question } from "phosphor-react";

export const Cadastro = ({ api, setDados, dados }) => {
  const { idIntegrador } = useParams();
  const { mudarTema, getTemaDarkAtivo } = useContext(TemaCustomizadoContext);
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const [planoSelecionado, setPlanoSelecionado] = useState({});
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [carregandoCidades, setCarregandoCidades] = useState(false);
  const [listagemCidade, setListagemCidade] = useState([]);
  const [listagemCidadeSeusDados, setListagemCidadeSeusDados] = useState([]);
  const [listagemUF, setListagemUF] = useState([]);
  const [abrirModalCadastroFinalizado, setAbrirModalCadastroFinalizado] =
    useState(false);
  const [abrirModalRecuperarDados, setAbrirModalRecuperarDados] =
    useState(false);
  const [dadosTodasEtapas, setDadosTodasEtapas] = useState(
    buscarDadosLocalStorage()
  );
  const { abrirCarregamento, fecharCarregamento } = useLoader();
  const [planos, setPlanos] = useState([]);
  const executeRecaptcha = useRecaptchaV3(
    "6Lc5LR0qAAAAAFuUlbkKvsbLqtOQrRB9I_7wRFq8"
  );
  const navigate = useNavigate();
  const yupSchemaDadosEmpresa = object().shape({
    CPFECNPJ: string().required(),
    IEERG: string().required(),
    razaoSocialNome: string().required(),
    nomeFantasiaApelido: string(),
    urlAmigavel: string().required(),

    telefone: string(),
    celular: string().required().min(11),
    email: string(),
    site: string(),

    CEP: string().required(),
    estado: string().required(),
    cidade: string().required(),
    logradouro: string(),
    Número: string().nullable(),
    bairro: string(),
    complemento: string(),
    pontoReferencia: string(),

    escolhaSegmento: string(),
    ondeConheceu: string(),
  });

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchemaDadosEmpresa),
    defaultValues: {
      CPFECNPJ: dados.cpfecnpj,
      email: dados.email,
      estado: "",
      cidade: "",
      nomeFantasiaApelido: "",
    },
  });
  // const eighteenYearsAgo = dayjs().subtract(18, 'years').toDate();
  const yupSchemaSeusDados = object().shape({
    nome: string().required(),
    cpf: string()
      .required()
      .test((value) => validarCPF(value)),
    dataNascimento: date()
      .required()
      .max(new Date(), "Não é possível incluir uma data futura"),
    genero: string(),

    telefone: string(),
    celular: string().required().min(11),
    email: string(),
    site: string(),

    cep: string().required(),
    estado: string().required(),
    cidade: string().required(),
    logradouro: string().required(),
    numero: string().nullable(),
    bairro: string(),
    complemento: string(),
    pontoReferencia: string(),
  });
  const {
    control: controlSeusDados,
    getValues: getValuesSeusDados,
    setValue: setValueSeusDados,
    handleSubmit: handleSubmitSeusDados,
    watch: watchSeusDados,
    reset: resetSeusDados,
    formState: { errors: errorsSeusDados },
  } = useForm({
    resolver: yupResolver(yupSchemaSeusDados),
    defaultValues: {
      genero: "Selecione",
    },
  });

  const yupSchemaDadosLogin = object().shape({
    usuario: string().required(),
    email: string().email().required(),
    senha: string()
      .required()
      .test((e) => validarSenha(e)),
    confirmarSenha: string()
      .required("Confirmação de senha é obrigatória.")
      .oneOf([ref("senha"), null], "As senhas não coincidem!"),
  });
  const {
    control: controlDadosLogin,
    getValues: getValuesDadosLogin,
    setValue: setValueDadosLogin,
    handleSubmit: handleSubmitDadosLogin,
    watch: watchDadosLogin,
    reset: resetDadosLogin,
    formState: { errors: errorsDadosLogin, isSubmitting },
  } = useForm({
    resolver: yupResolver(yupSchemaDadosLogin),
    defaultValues: {
      email: dados.email,
    },
  });

  async function buscarTodasUFs() {
    try {
      const res = await api({
        method: "get",
        url: `/waybe/cep/buscar-todas/unidades-federativas?size=999`,
      });

      setListagemUF(res.data.content);
    } catch (e) {
      console.error(e);
    } finally {
      setCarregandoCidades(false);
    }
  }

  function onHandleSubmit(dados) {
    setPaginaAtual(2);
    setDadosTodasEtapas({ ...dadosTodasEtapas, dadosDaEmpresa: dados });
    largura < 768 &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }
  async function buscarCidades(estado, cidadeDefault = null) {
    setValue("cidade", "");
    setListagemCidade([]);
    if (!estado) {
      return;
    }
    setCarregandoCidades(true);
    try {
      const res = await api({
        method: "get",
        url: `/waybe/cep/buscar-todas-cidades/por-uf?uf=${estado}&size=9999`,
      });

      setListagemCidade(res.data.content ? res.data.content : []);
      if (cidadeDefault) {
        setValue("cidade", cidadeDefault);
      }
      return res.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      setCarregandoCidades(false);
    }
  }
  async function buscarCidadesSeusDados(estado, cidadeDefault = null) {
    setValueSeusDados("cidade", "");
    setListagemCidadeSeusDados([]);
    if (!estado) {
      return;
    }
    setCarregandoCidades(true);
    try {
      const res = await api({
        method: "get",
        url: `/waybe/cep/buscar-todas-cidades/por-uf?uf=${estado}&size=9999`,
      });

      setListagemCidadeSeusDados(res.data.content ? res.data.content : []);
      if (cidadeDefault) {
        setValueSeusDados("cidade", cidadeDefault);
      }
      return res.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      setCarregandoCidades(false);
    }
  }

  function onHandleSubmitSeusDados(dados) {
    setPaginaAtual(3);
    setDadosTodasEtapas({ ...dadosTodasEtapas, seusDados: dados });
    largura < 768 &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }
  async function onHandleSubmitDadosLogin(dados) {
    abrirCarregamento();
    const recaptchaToken = await executeRecaptcha("login");

    const response = await verificarCaptcha(recaptchaToken);

    const dadosTodasTelas = {
      dadosLogin: { ...dados },
      dadosDaEmpresa: { ...getValues() },
      seusDados: { ...getValuesSeusDados() },
      planoSelecionado,
    };

    if (response) {
      executarProcessosDeCadastro(dadosTodasTelas);
    } else {
      fecharCarregamento();
      toast.error("Bot detectado");
    }
  }

  async function verificarCaptcha(token) {
    try {
      const res = await api(
        `/usuario-sessao/captcha/validate?g-recaptcha-response=${token}`
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  const watchedCepSeusDados = watchSeusDados("cep"); // you can also target specific fields by their names
  useEffect(() => {
    if (watchedCepSeusDados?.length === 9) {
      preenchimentoAutomaticoCep(watchedCepSeusDados, "seusDados");
    }
  }, [watchedCepSeusDados]);

  const watchedCep = watch("CEP"); // you can also target specific fields by their names
  useEffect(() => {
    if (watchedCep?.length === 9) {
      preenchimentoAutomaticoCep(watchedCep, "dadosDaEmpresa");
    }
  }, [watchedCep]);

  async function preenchimentoAutomaticoCep(cep, etapa) {
    if (!cep) {
      return;
    }
    let cepLimpo = cep.replace(/\D/g, "");

    if (cepLimpo?.length === 8) {
      try {
        const dadosLogradouro = await api({
          method: "get",
          url: `/waybe/cep/buscar-logradouro/${cepLimpo}`,
        });

        if (dadosLogradouro.status == 200 && dadosLogradouro?.data?.nome) {
          if (etapa == "dadosDaEmpresa") {
            setValue("logradouro", dadosLogradouro.data.nome);
          } else if (etapa == "seusDados") {
            setValueSeusDados("logradouro", dadosLogradouro.data.nome);
          }

          const dadosBairro = await api({
            method: "get",
            url: `/waybe/cep/buscar-bairro/${dadosLogradouro.data.idBairro}`,
          });

          if (dadosBairro.status == 200 && dadosBairro?.data?.nome) {
            if (etapa == "dadosDaEmpresa") {
              setValue("bairro", dadosBairro.data.nome);
            } else if (etapa == "seusDados") {
              setValueSeusDados("bairro", dadosBairro.data.nome);
            }

            const dadosCidade = await api({
              method: "get",
              url: `/waybe/cep/buscar-cidade/${dadosBairro.data.idCidade}`,
            });
            if (dadosCidade.status == 200 && dadosCidade?.data?.nome) {
              if (etapa == "dadosDaEmpresa") {
                setValue("estado", dadosCidade.data.unidadeFederativa);
                buscarCidades(
                  dadosCidade.data.unidadeFederativa,
                  dadosCidade.data.nome
                );
              } else if (etapa == "seusDados") {
                setValueSeusDados("estado", dadosCidade.data.unidadeFederativa);
                buscarCidadesSeusDados(
                  dadosCidade.data.unidadeFederativa,
                  dadosCidade.data.nome
                );
              }
            }
          }
        }
      } catch (error) {
        console.error("Erro ao buscar dados do CEP:", error);
      }
    }
  }

  const validarSenha = (senha) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(senha);
  };

  const ModalConfirmar = ({ close }) => {
    return (
      <ConteudoPopUp>
        <HeaderPopup>
          <TextoTitulo>Recuperação de dados</TextoTitulo>
          <XIcon onClick={close} />
        </HeaderPopup>
        <ContainerTextoItemSelecionado>
          <TextoItemSelecionadoExcluir>
            Você começou um cadastro anteriormente e não o finalizou. Deseja
            recuperá-lo?
          </TextoItemSelecionadoExcluir>
        </ContainerTextoItemSelecionado>

        <ContainerBotoesExcluir>
          <BotaoVermelhoVazado
            texto="Não"
            onClick={() => {
              close();

              localStorage.setItem(
                "DADOS-CADASTRO-WAYMENU-V1.0",
                JSON.stringify({
                  dadosIniciais: dados.cpfecnpj,
                })
              );
            }}
            tamanho="SM"
          />
          <BotaoAzulPreenchido
            onClick={() => {
              setarDadosRecuperados();
              close();
            }}
            texto="Sim"
            tamanho="SM"
          />
        </ContainerBotoesExcluir>
      </ConteudoPopUp>
    );
  };

  const ModalCadastroFinalizado = () => {
    return (
      <ConteudoPopUp>
        <HeaderPopup>
          <TextoTituloModal>Cadastro Finalizado!</TextoTituloModal>
        </HeaderPopup>
        <ContainerTexto>
          <TextoItemSelecionado>
            Parabéns {getValuesSeusDados("nome")}!!
          </TextoItemSelecionado>
          <TextoItemSelecionado>
            Em breve, você receberá um e-mail com os links para baixar os
            instaladores.
          </TextoItemSelecionado>
        </ContainerTexto>
        <img
          src={
            getTemaDarkAtivo
              ? ImgCadastroFinalizadoDark
              : ImgCadastroFinalizadoLight
          }
          style={{ width: "100%" }}
        />
        <ContainerBotoesExcluir>
          <BotaoAzulPreenchido
            onClick={() => {
              navigate("/login");
            }}
            texto="Acessar ERP"
            tamanho="MD"
          />
        </ContainerBotoesExcluir>
      </ConteudoPopUp>
    );
  };

  function buscarDadosLocalStorage() {
    let dadosLocalStorage = JSON.parse(
      localStorage.getItem("DADOS-CADASTRO-WAYMENU-V1.0")
    );

    if (dadosLocalStorage && Object.values(dadosLocalStorage).length > 0) {
      if (dadosLocalStorage.dadosIniciais === dados.cpfecnpj) {
        return dadosLocalStorage;
      } else {
        localStorage.clear("DADOS-CADASTRO-WAYMENU-V1.0");
      }
    }

    return {
      dadosIniciais: dados.cpfecnpj,
    };
  }
  function setarDadosRecuperados() {
    try {
      let dadosLocalStorage = JSON.parse(
        localStorage.getItem("DADOS-CADASTRO-WAYMENU-V1.0")
      );
      setDadosTodasEtapas(dadosLocalStorage);

      setPlanoSelecionado(dadosLocalStorage.planoSelecionado);
      dadosLocalStorage.dadosDaEmpresa &&
        Object.entries(dadosLocalStorage.dadosDaEmpresa).forEach(
          async (item) => {
            if (item[0] == "CPFECNPJ") {
              return;
            }

            if (item[0] == "cidade") {
              const cidades = await buscarCidades(getValues("estado"));
            }
            setValue(item[0], item[1]);
          }
        );

      dadosLocalStorage.seusDados &&
        Object.entries(dadosLocalStorage.seusDados).forEach(async (item) => {
          if (item[0] == "estado") {
            await buscarCidadesSeusDados(item[1]);
          }
          if (item[0] == "cidade") {
            const cidades = await buscarCidadesSeusDados(
              getValuesSeusDados("estado")
            );
          }

          if (item[0] == "dataNascimento") {
            setValueSeusDados(item[0], dayjs(item[1]));
            return;
          }

          setValueSeusDados(item[0], item[1]);
        });

      toast.success("Dados recuperados com sucesso!");
    } catch (error) {
      console.error(error);
      toast.error("Houve um erro ao recuperar os dados");
    }
  }

  async function buscarDadosCNPJ(cnpj) {
    const cnpjLimpo = cnpj.replace(/[^0-9]/g, "");

    if (cnpjLimpo.length < 14) {
      return;
    }
    try {
      const res = await fetch(
        `https://brasilapi.com.br/api/cnpj/v1/${cnpjLimpo}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();
      if (res.status == 200) {
        setValue("estado", data.uf);
        buscarCidades(data.uf, data.municipio);
        setValue("logradouro", data.logradouro);
        setValue("bairro", data.bairro);
        setValue("Número", data.numero);
        setValue("complemento", data.complemento);
        setValue("CEP", data.cep.replace(/(\d{5})(\d{3})/, "$1-$2 "));
        setValue("razaoSocialNome", data.razao_social);
        if (!data.nome_fantasia) {
          setValue("nomeFantasiaApelido", data.razao_social);
        } else {
          setValue("nomeFantasiaApelido", data.nome_fantasia);
        }
      }
      encontrarCidade(data.municipio);
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  }

  function encontrarCidade(cidade) {
    const result = listagemCidade.find(
      (item) => item.nome.toUpperCase() === cidade
    );
    if (Object.keys(result).length > 0) {
      setValue("cidade", result.nome);
    }
  }
  async function fetchPlanos() {
    try {
      const res = await api(`/contrato/wizard/planos-waymenu/${idIntegrador}`);
      setPlanos(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function inserirEmpresa() {
    try {
      let urlAmigavel = getValues("urlAmigavel");
      const res = await api.post(
        `/configuracoes-lojas/wizard/empresa/inserir/${idIntegrador}`,
        {
          "urlAmigavel": urlAmigavel,
        }
      );
      return res.data;
    } catch (error) {
      console.error(error);
      toast.error("Houve um erro ao cadastrar, tente novamente mais tarde!");
    }
  }

  async function inserirPessoaFisica(idEmpresa, dados) {
    try {
      const res = await api.post(`/pessoa/wizard/pessoa/${idEmpresa}`, {
        "pessoa": {
          "nome": dados.seusDados.nome,
          "inscricaoFederal": dados.seusDados.cpf,
          "email": dados.seusDados.email,
          "site": dados.seusDados.site,
          "tipoPessoa": "FISICA",
          "pessoaFisica": {
            "dataNascimento": dados.seusDados.dataNascimento,
            "genero":
              dados.seusDados.genero !== "Selecione"
                ? dados.seusDados.genero.toUpperCase()
                : null,
          },
        },

        "endereco": {
          "descricao": "Endereço Principal",
          "logradouro": dados.seusDados.logradouro,
          "numero": dados.seusDados.numero,
          "complemento": dados.seusDados.complemento,
          "bairro": dados.seusDados.bairro,
          "cep": formatarCEP(dados.seusDados.cep),
          "pontoReferencia": dados.seusDados.pontoReferencia,
          "idCidade": listagemCidadeSeusDados.find(
            (item) => item.nome === dados.seusDados.cidade
          )?.id,
          ativo: true,
        },
        "telefones": [
          ...(dados.seusDados.telefone
            ? [
              {
                "descricao": "Telefone",
                "tipo": "RESIDENCIAL",
                "telefone": dados.seusDados.telefone,
              },
            ]
            : []),
          {
            "descricao": "Celular",
            "tipo": "CELULAR",
            "telefone": dados.seusDados.celular,
          },
        ],
      });

      return res.data;
    } catch (error) {
      console.error(error);
      toast.error("Houve um erro ao cadastrar, tente novamente mais tarde!");
    }
  }
  async function inserirPessoaJuridica(idEmpresa, dados) {
    try {
      const res = await api.post(`/pessoa/wizard/pessoa/${idEmpresa}`, {
        "pessoa": {
          "nome": dados.dadosDaEmpresa.razaoSocialNome,
          "apelido": dados.dadosDaEmpresa.nomeFantasiaApelido,
          "inscricaoFederal": dados.dadosDaEmpresa.CPFECNPJ,
          "inscricaoEstadual": dados.dadosDaEmpresa.IEERG,
          "email": dados.dadosDaEmpresa.email,
          "site": dados.dadosDaEmpresa.site,
          "tipoPessoa": "JURIDICA",
          "pessoaJuridica": {},
          "pessoaFisica": {},
        },

        "endereco": {
          "descricao": "Endereço Principal",
          "logradouro": dados.dadosDaEmpresa.logradouro,
          "numero": dados.dadosDaEmpresa.Número,
          "complemento": dados.dadosDaEmpresa.complemento,
          "bairro": dados.dadosDaEmpresa.bairro,
          "cep": formatarCEP(dados.dadosDaEmpresa.CEP),
          "pontoReferencia": dados.dadosDaEmpresa.pontoReferencia,
          "idCidade": listagemCidade.find(
            (item) => item.nome === dados.dadosDaEmpresa.cidade
          )?.id,
          "ativo": true,
        },
        "telefones": [
          ...(dados.dadosDaEmpresa.telefone
            ? [{
              "descricao": "TELEFONE",
              "tipo": "RESIDENCIAL",
              "telefone": dados.dadosDaEmpresa.telefone,
            }]
            : []),
          {
            "descricao": "CELULAR",
            "tipo": "CELULAR",
            "telefone": dados.dadosDaEmpresa.celular,
          },
        ],

      });
      console.log(res);
      return res;
    } catch (error) {
      console.error(error);

      toast.error("Houve um erro ao cadastrar, tente novamente mais tarde!");
    }
  }
  async function atualizarEmpresa(idEmpresa, idPessoa, idContrato) {
    try {
      const res = await api.put(`configuracoes-lojas/empresa/${idEmpresa}`, {
        idPessoa,
        idEmpresaContratoWaybe: idContrato,
      });
    } catch (error) {
      console.error(error);
      toast.error("Houve um erro ao cadastrar, tente novamente mais tarde!");
    }
  }
  async function inserirContrato(idEmpresa, dados, idPessoa) {
    try {
      const res = await api.post(
        `/contrato/wizard/waybe/empresa-contrato/nova-empresa`,
        {
          idEmpresa,
          "idPlanoPadraoWaymenu": planoSelecionado.id,
          "tipoRecorrenciaPlano": "MENSAL",
          idIntegrador,
        }
      );
      const idContrato = res.data;
      return res;
    } catch (error) {
      console.error(error);
      toast.error("Houve um erro ao cadastrar, tente novamente mais tarde!");
    }
  }
  async function cadastrarUsuario(idEmpresa, dados) {
    try {
      const telefone = dados.seusDados.celular.replace(/\D+/g, '')
      const res = await api.post(`usuario-sessao/wizard/usuario`, {
        "nome": dados.seusDados.nome,
        "apelido": dados.seusDados.nome,
        "idEmpresa": idEmpresa,
        "cpf": dados.seusDados.cpf,
        "email": dados.seusDados.email,
        "telefone": telefone,
        "username": dados.dadosLogin.usuario,
        "password": dados.dadosLogin.senha,
        "genero":
          dados.seusDados.genero !== "Selecione"
            ? dados.seusDados.genero.toUpperCase()
            : null,
        "dataNascimento": dados.seusDados.dataNascimento,
        // 'usuarioEmpresaDTO': {
        //   'idEmpresa': idEmpresa,
        // },
      });
      setAbrirModalCadastroFinalizado(true);

    } catch (error) {
      console.error(error);

      toast.error(error.response.data.message);
    } finally {
      fecharCarregamento();
    }
  }

  async function executarProcessosDeCadastro(dados) {
    try {
      const idEmpresa = await inserirEmpresa(dados);

      const idPessoa = await inserirPessoaFisica(idEmpresa, dados);

      const promessas = [
        inserirPessoaJuridica(idEmpresa, dados),
        inserirContrato(idEmpresa, dados, idPessoa),
        cadastrarUsuario(idEmpresa, dados),
      ];

      await Promise.all(promessas).then(async (response) => {
        console.log(response);
        const idPessoaEmpresa = response[0]?.data;
        const idContrato = response[1].data;
        await atualizarEmpresa(idEmpresa, idPessoaEmpresa, idContrato);
      });

      localStorage.clear("DADOS-CADASTRO-WAYMENU-V1.0");
    } catch (error) {
      console.error("Um erro ocorreu durante o processamento", error);
    } finally {
      fecharCarregamento();
    }
  }

  const [botaoHabilitado, setBotaoHabilitado] = useState(false);

  useEffect(() => {
    buscarTodasUFs();
    buscarDadosCNPJ(dados.cpfecnpj);
    fetchPlanos();
    let dadosLocalStorage = JSON.parse(
      localStorage.getItem("DADOS-CADASTRO-WAYMENU-V1.0")
    );

    if (
      dadosLocalStorage &&
      dadosLocalStorage.dadosIniciais === dados.cpfecnpj &&
      Object.keys(dadosLocalStorage).length > 1
    ) {
      setAbrirModalRecuperarDados(true);
    } else {
      localStorage.clear("DADOS-CADASTRO-WAYMENU-V1.0");
      localStorage.setItem(
        "DADOS-CADASTRO-WAYMENU-V1.0",
        JSON.stringify({
          dadosIniciais: dados.cpfecnpj,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0)
      toast.error("Preencha todos os campos obrigatórios!");
    if (Object.keys(errorsSeusDados).length > 0)
      toast.error("Preencha todos os campos obrigatórios!");
    if (Object.keys(errorsDadosLogin).length > 0)
      toast.error("Preencha todos os campos obrigatórios!");
  }, [errors, errorsSeusDados, errorsDadosLogin]);

  useEffect(() => {
    localStorage.setItem(
      "DADOS-CADASTRO-WAYMENU-V1.0",
      JSON.stringify(dadosTodasEtapas)
    );
  }, [dadosTodasEtapas]);

  return (
    <>
      <ContainerGlobal>
        <StyledHeader>
          <div>
            <TextoTitulo>Boas Vindas ao</TextoTitulo>
            <ImagemWaybe url={ImgWaybe} />
          </div>

          {paginaAtual !== 0 && largura > 768 && (
            <CardPlanoSelecionado>
              <div>
                <p>Plano Selecionado:</p>
                {planoSelecionado.descricao.toLowerCase() == "grátis" ? (
                  <p>100% grátis</p>
                ) : (
                  <p>Teste grátis por 7 dias</p>
                )}
              </div>
              <div>
                <h1>{planoSelecionado.descricao}</h1>
                <h1>{formatarReal(planoSelecionado.valorMensal)}/mês</h1>
                <BotaoAzulSemFundo
                  texto="Alterar plano"
                  onClick={() => setPaginaAtual(0)}
                />
              </div>
            </CardPlanoSelecionado>
          )}
          <Switch
            onClick={() => {
              mudarTema();
            }}
            checked={!theme.temaDarkAtivo}
            valor={!theme.temaDarkAtivo}
            style={largura < 768 && { position: "absolute", top: 10, right: 0 }}
            mudarTema
          />
        </StyledHeader>
        <ContainerConteudo>
          <Conteudo>
            <ContainerEtapas>
              <Etapa atual={paginaAtual === 0 || paginaAtual > 0}>
                <span>
                  {paginaAtual > 0 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    1
                  )}
                </span>
                {largura > 768 && <p>Escolha do plano</p>}
                {largura < 768 && paginaAtual == 0 && <p>Escolha do plano</p>}
              </Etapa>
              <Separador />
              <Etapa atual={paginaAtual === 1 || paginaAtual > 1}>
                <span>
                  {paginaAtual > 1 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    2
                  )}
                </span>
                {largura > 768 && <p>Dados da empresa</p>}
                {largura < 768 && paginaAtual == 1 && <p>Dados da empresa</p>}
              </Etapa>
              <Separador />
              <Etapa atual={paginaAtual === 2 || paginaAtual > 2}>
                <span>
                  {paginaAtual > 2 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    3
                  )}
                </span>
                {largura > 768 && <p>Seus dados</p>}
                {largura < 768 && paginaAtual == 2 && <p>Seus dados</p>}
              </Etapa>
              <Separador />
              <Etapa atual={paginaAtual === 3 || paginaAtual > 3}>
                <span>
                  {paginaAtual > 3 ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.cores.textoInverso }}
                    />
                  ) : (
                    4
                  )}
                </span>
                {largura > 768 && <p>Informações de acesso</p>}
                {largura < 768 && paginaAtual == 3 && <p>Inform. de acesso</p>}
              </Etapa>
            </ContainerEtapas>

            {paginaAtual === 0 && (
              <>
                <WrapperContratos>
                  {planos.map((item) => (
                    <Contrato
                      key={item.id}
                      onClick={() => {
                        setPlanoSelecionado(item);
                      }}
                      selecionado={planoSelecionado.id === item.id}
                    >
                      <h1>{item.descricao}</h1>
                      <span>{formatarReal(item.valorMensal)}</span>
                      <p>Por mês</p>
                      {item.descricao.toLowerCase() == "grátis" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos até 50 produtos</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber até R$5.000 por mês</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 1 conexão ativa</p>
                            </div>
                          </Especificacoes>
                          <p>100% Grátis</p> */}
                          {/* <ChipRecomendado>Recomendado</ChipRecomendado> */}
                        </>
                      )}
                      {item.descricao.toLowerCase() == "bronze" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 2 conexões ativas</p>
                            </div>
                          </Especificacoes>
                          <p>Teste grátis por 7 dias</p>
                          <ChipRecomendado>Recomendado</ChipRecomendado> */}
                        </>
                      )}
                      {item.descricao.toLowerCase() == "prata" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 3 conexões ativas</p>
                            </div>
                          </Especificacoes>
                          <p>Teste grátis por 7 dias</p> */}
                        </>
                      )}
                      {item.descricao.toLowerCase() == "ouro" && (
                        <>
                          {/* <Especificacoes>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Cadastro de produtos Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Contas a receber Ilimitado</p>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color:
                                    theme.cores
                                      .semanticSuccess3SemanticSuccess2,
                                }}
                              />
                              <p>Usuários ilimitados e 4 conexões ativas</p>
                            </div>
                          </Especificacoes>
                          <p>Teste grátis por 7 dias</p> */}
                        </>
                      )}
                    </Contrato>
                  ))}
                </WrapperContratos>

                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Voltar"
                    onClick={() => window.location.reload()}
                  />
                  <BotaoAzulPreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Próximo"
                    onClick={() => {
                      if (planoSelecionado.id) {
                        setPaginaAtual(1);
                        setDadosTodasEtapas({
                          ...dadosTodasEtapas,
                          planoSelecionado: planoSelecionado,
                        });
                        largura < 768 &&
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                      } else {
                        toast.error(
                          "Selecione ao menos um plano para continuar!"
                        );
                      }
                    }}
                  />
                </ContainerBotoes>
              </>
            )}
            {paginaAtual === 1 && (
              <>
                <ContainerTitulo>
                  <h1>Informações gerais</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={control}
                    name="CPFECNPJ"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={dados.cpfecnpj.length === 18 ? "CNPJ" : "CPF"}
                        value={field.value}
                        onChange={field.onChange}
                        disabled
                        error={errors.CPFECNPJ}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="IEERG"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={dados.cpfecnpj.length === 18 ? "IE" : "RG"}
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 20) {
                            field.onChange(e);
                          }
                        }}
                        required
                        error={errors.IEERG}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="razaoSocialNome"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={
                          dados.cpfecnpj.length === 18 ? "Razão social" : "Nome"
                        }
                        value={field.value}
                        onChange={(e) => {
                          if (
                            e.target.value.length < dados.cpfecnpj.length === 18
                              ? 100
                              : 50
                          ) {
                            field.onChange(e);
                            setValue("nomeFantasiaApelido", e.target.value);
                          }
                        }}
                        defaultValue={" "}
                        required
                        error={errors.razaoSocialNome}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="nomeFantasiaApelido"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo={
                          dados.cpfecnpj.length === 18
                            ? "Nome fantasia"
                            : "Apelido"
                        }
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="urlAmigavel"
                    render={({ field }) => (
                      // <CampoDeTexto
                      //   titulo={"URL Amigável"}
                      //   value={field.value}
                      //   onChange={field.onChange}
                      //   error={errors.urlAmigavel}
                      // />
                      <CampoDeTexto
                        // InputLabelProps={{ style: { pointerEvents: "auto" } }}
                        InputProps={{
                          endAdornment: (
                            <CustomTooltip
                              title={
                                "Insira uma URL personalizada para sua empresa, sem espaços ou caracteres especiais, que será utilizada pelos seus consumidores para acessar o seu cardápio. \nExemplo: 'minha-empresa'"
                              }
                              placement="top"
                              arrow
                            >
                              <Question
                                color={theme.cores.brand40Default}
                                size={16}
                                weight="bold"
                              />
                            </CustomTooltip>
                          ),
                        }}
                        value={field.value}
                        onChange={field.onChange}
                        error={errors.urlAmigavel}
                        titulo="URL Amigável"
                      />
                    )}
                  />
                </ContainerInputs>
                <ContainerTitulo>
                  <h1>Dados de contato</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={control}
                    name="telefone"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Telefone"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCelularTempoReal(e));
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="celular"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Celular"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCelularTempoReal(e));
                        }}
                        error={errors.celular}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="E-mail"
                        value={field.value}
                        onChange={field.onChange}
                        error={
                          watch("email") !== "" && !validarEmail(watch("email"))
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="site"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Site"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            field.onChange(e);
                          }
                        }}
                      />
                    )}
                  />
                </ContainerInputs>
                <ContainerTitulo>
                  <h1>Endereço</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={control}
                    name="CEP"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="CEP"
                        // autoComplete="off"
                        required
                        value={field.value}
                        onChange={(e) => {
                          if (formatarCEP(e.target.value)) {
                            field.onChange(formatarCEP(e.target.value));
                          }
                        }}
                        error={errors.CEP}
                      />
                    )}
                  />
                  <Controller
                    name="estado"
                    control={control}
                    render={({ field }) => (
                      <StyledAutocomplete
                        disablePortal
                        autoSelect={false}
                        inputValue={field.value ? field.value : ""}
                        onChange={(e, value) => {
                          field?.onChange(value ? value : "");
                          buscarCidades(value ? value : "");
                        }}
                        value={field.value ? field.value : ""}
                        options={
                          listagemUF?.length
                            ? listagemUF?.map((item) => item.uf)
                            : []
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.estado}
                            label="Estado"
                            required
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="cidade"
                    control={control}
                    render={({ field }) => (
                      <StyledAutocomplete
                        disablePortal
                        loading={carregandoCidades}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        value={field.value}
                        disabled={!listagemCidade.length}
                        options={
                          listagemCidade
                            ? listagemCidade.map((item) => item.nome)
                            : []
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cidade"
                            error={errors.cidade}
                            required
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {carregandoCidades ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="logradouro"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Logradouro"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="Número"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Número"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="bairro"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Bairro"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="complemento"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Complemento"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="pontoReferencia"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Ponto de referência"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            field.onChange(e);
                          }
                        }}
                      />
                    )}
                  />
                </ContainerInputs>
                {/* <ContainerTitulo>
                                <h1>Negócio</h1>
                                <Separador />
                            </ContainerTitulo>
                            <ContainerDoisInputs>
                                <Controller
                                    control={control}
                                    name="escolhaSegmento"
                                    render={({ field }) => (
                                        <CampoDeTexto
                                            titulo="Escolha o segmento do seu negócio"
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="ondeConheceu"
                                    render={({ field }) => (
                                        <CampoDeTexto
                                            titulo="Por onde nos conheceu?"
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </ContainerDoisInputs> */}

                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Voltar"
                    onClick={() => setPaginaAtual(0)}
                  />
                  <BotaoAzulPreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Próximo"
                    onClick={handleSubmit(onHandleSubmit)}
                  />
                </ContainerBotoes>
              </>
            )}
            {paginaAtual === 2 && (
              <>
                <ContainerTitulo>
                  <h1>Informações gerais</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={controlSeusDados}
                    name="nome"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Nome completo"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            field.onChange(e);
                          }
                        }}
                        error={errorsSeusDados.nome}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="cpf"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="CPF"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCPFTempoReal(e));
                        }}
                        error={errorsSeusDados.cpf}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="dataNascimento"
                    render={({ field }) => (
                      <CampoDeDataSemHora
                        titulo="Data de nascimento *"
                        value={field.value}
                        onChange={field.onChange}
                        required
                        error={errorsSeusDados.dataNascimento}
                        maxDate={dayjs()}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="genero"
                    render={({ field }) => (
                      <CampoDeSelecao
                        titulo="Gênero"
                        valor={field.value}
                        onChange={field.onChange}
                        campoVazio={false}
                        items={["Selecione", "Feminino", "Masculino", "Outros"]}
                      />
                    )}
                  />
                </ContainerInputs>
                <ContainerTitulo>
                  <h1>Dados de contato</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={controlSeusDados}
                    name="telefone"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Telefone"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCelularTempoReal(e));
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="celular"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Celular"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(formatarCelularTempoReal(e));
                        }}
                        error={errorsSeusDados.celular}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="email"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="E-mail"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="site"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Site"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </ContainerInputs>
                <ContainerTitulo>
                  <h1>Endereço</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerInputs>
                  <Controller
                    control={controlSeusDados}
                    name="cep"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="CEP"
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        required
                        value={field.value}
                        onChange={(e) => {
                          if (formatarCEP(e.target.value)) {
                            field.onChange(formatarCEP(e.target.value));
                          }
                        }}
                        error={errorsSeusDados.cep}
                      />
                    )}
                  />
                  <Controller
                    name="estado"
                    control={controlSeusDados}
                    render={({ field }) => (
                      <StyledAutocomplete
                        disablePortal
                        autoSelect={false}
                        inputValue={field.value ? field.value : ""}
                        onChange={(e, value) => {
                          field?.onChange(value ? value : "");
                          buscarCidadesSeusDados(value ? value : "");
                        }}
                        value={field.value ? field.value : ""}
                        options={
                          listagemUF?.length
                            ? listagemUF?.map((item) => item.uf)
                            : []
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: field?.value?.length > 0,
                            }}
                            error={errorsSeusDados.estado}
                            label="Estado"
                            required
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="cidade"
                    control={controlSeusDados}
                    render={({ field }) => (
                      <StyledAutocomplete
                        disablePortal
                        loading={carregandoCidades}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        value={field.value}
                        disabled={!listagemCidadeSeusDados.length}
                        options={
                          listagemCidade
                            ? listagemCidadeSeusDados.map((item) => item.nome)
                            : []
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cidade"
                            error={errorsSeusDados.cidade}
                            required
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {carregandoCidades ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="logradouro"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Logradouro"
                        value={field.value}
                        onChange={field.onChange}
                        required
                        error={errorsSeusDados.logradouro}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="numero"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Número"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="bairro"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Bairro"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="complemento"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Complemento"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={controlSeusDados}
                    name="pontoReferencia"
                    render={({ field }) => (
                      <CampoDeTexto
                        InputLabelProps={{
                          shrink: field?.value?.length > 0,
                        }}
                        titulo="Ponto de referência"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            field.onChange(e);
                          }
                        }}
                      />
                    )}
                  />
                </ContainerInputs>

                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Voltar"
                    onClick={() => setPaginaAtual(1)}
                  />
                  <BotaoAzulPreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Próximo"
                    onClick={handleSubmitSeusDados(onHandleSubmitSeusDados)}
                  />
                </ContainerBotoes>
              </>
            )}
            {paginaAtual === 3 && (
              <>
                <ContainerTitulo>
                  <h1>Login</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerDoisInputs>
                  <Controller
                    control={controlDadosLogin}
                    name="usuario"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="Usuário"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            if (e.target.value === "") {
                              field.onChange(e);
                            }
                            if (RegExp(/^[^\s]+$/).test(e.target.value)) {
                              field.onChange(e);
                            }
                          }
                        }}
                        required
                        error={errorsDadosLogin.usuario}
                      />
                    )}
                  />
                  <Controller
                    control={controlDadosLogin}
                    name="email"
                    render={({ field }) => (
                      <CampoDeTexto
                        titulo="E-mail"
                        value={field.value}
                        onChange={field.onChange}
                        error={errorsDadosLogin.email}
                        required
                        disabled
                      />
                    )}
                  />
                </ContainerDoisInputs>
                <ContainerTitulo>
                  <h1>Senha</h1>
                  <Separador />
                </ContainerTitulo>
                <ContainerDoisInputs>
                  <Controller
                    control={controlDadosLogin}
                    name="senha"
                    render={({ field }) => (
                      <CampoDeSenha
                        titulo="Senha"
                        value={field.value}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            field.onChange(e);
                          } else if (RegExp(/^[^\s]+$/).test(e.target.value)) {
                            field.onChange(e);
                          }
                        }}
                        error={errorsDadosLogin.senha}
                        required
                      />
                    )}
                  />
                  <Controller
                    control={controlDadosLogin}
                    name="confirmarSenha"
                    render={({ field }) => (
                      <Bloco>
                        <CampoDeSenha
                          titulo="Confirmar senha"
                          value={field.value}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              field.onChange(e);
                            } else if (
                              RegExp(/^[^\s]+$/).test(e.target.value)
                            ) {
                              field.onChange(e);
                            }
                          }}
                          required
                          error={
                            errorsDadosLogin.senha ||
                            watchDadosLogin("senha") !== field.value
                          }
                        />
                        {watchDadosLogin("senha") !== field.value && (
                          <p>As senhas não coincidem!</p>
                        )}
                      </Bloco>
                    )}
                  />
                </ContainerDoisInputs>

                <EspecificacoesSenha>
                  <h1>Sua senha precisa ter:</h1>

                  <div>
                    {watchDadosLogin("senha")?.length >= 8 ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>8 caracteres</p>
                  </div>
                  <div>
                    {RegExp(/(?=.*[A-Z])/).test(watchDadosLogin("senha")) ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>1 letra maiúscula</p>
                  </div>
                  <div>
                    {RegExp(/[a-z]/).test(watchDadosLogin("senha")) &&
                      typeof watchDadosLogin("senha") !== "undefined" ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>1 letra minúscula</p>
                  </div>
                  <div>
                    {RegExp(/(?=.*\d)/).test(watchDadosLogin("senha")) ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: theme.cores.semanticSuccess3SemanticSuccess2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: theme.cores.semanticDanger2,
                          width: 15,
                          height: 15,
                        }}
                      />
                    )}
                    <p>1 número</p>
                  </div>
                </EspecificacoesSenha>
                <ContainerBotoes>
                  <BotaoAzulVazado
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Voltar"
                    onClick={() => {
                      resetDadosLogin({
                        senha: "",
                        confirmarSenha: "",
                        usuario: "",
                        email: dados.email,
                      });
                      setPaginaAtual(2);
                    }}
                  />
                  <BotaoVerdePreenchido
                    tamanho={largura > 768 ? "MD" : "SM"}
                    texto="Finalizar cadastro"
                    disabled={isSubmitting && !botaoHabilitado}
                    onClick={handleSubmitDadosLogin(onHandleSubmitDadosLogin)}
                  />
                </ContainerBotoes>
              </>
            )}
          </Conteudo>
        </ContainerConteudo>
      </ContainerGlobal>
      <GerarModalResponsivo
        ativo={abrirModalRecuperarDados}
        children={(close) => <ModalConfirmar close={close} />}
      />
      <GerarModalResponsivo
        ativo={abrirModalCadastroFinalizado}
        closeOnEscape={false}
        permitirFechar={false}
        children={(close) => <ModalCadastroFinalizado />}
      />
    </>
  );
};

export default Cadastro;
