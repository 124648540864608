import { Carregando, PopupCustomizado, Logo } from "./styles";
import WaybeLogo from "../../assets/images/loading-logo.svg";


export const ModalCarregando = ({
    fechamento,
    titulo
}) => {
    return (
        <PopupCustomizado fechamento={fechamento}>
            <Carregando />
            <Logo src={WaybeLogo} />
        </PopupCustomizado>
    );
};
