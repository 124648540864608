import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    BotaoCartaoPadrao,
    BotaoDefinirCartaoPadrao,
    Cabecalho,
    Container,
    Footer,
    TextoBotaoCartaoPadrao,
    TextoBotaoDefinirCartaoPadrao,
    TextoCabecalho,
    TextoNumero
} from "./styles";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { IconeBotao } from "../../pages/selecaoEmpresas/styles";
import { useTheme } from "@emotion/react";
import { BotaoVermelhoPreenchido, BotaoVermelhoSemFundo, BotaoVermelhoVazado } from "../Botao";
import { CampoDeInputNumericoComSetas } from "../CampoInput";
import { GerarModalConfirmacao } from "../PopUpConfirmacao";

export function Cartao({
    nome,
    numero,
    cartaoPadrao = false,
    onRemove,
    finalizarCompra = false,
    cartaoSelecionado = false,
    trocarCartaoSelecionado,
    onClickDefinirComoPadrao
}) {

    const theme = useTheme();


    function BotaoRemover() {
        return (
            <GerarModalConfirmacao
                trigger={
                    <IconeBotao>
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            style={{ color: "#E74A3B" }}
                        />
                    </IconeBotao>
                }
                titulo='Remover cartão'
                subTitulo='Tem certeza que deseja remover o cartão?'

                botaoCancelar={(fechar) => <BotaoVermelhoVazado texto="Cancelar" tamanho="MD" onClick={fechar} />}

                botaoConfirmar={(fechar) => <BotaoVermelhoPreenchido texto="Remover" tamanho="MD"
                    onClick={() => onRemove(fechar)} />}

            />

        )
    }

    return (
        <>
            {!finalizarCompra ? (
                <Container>
                    <Cabecalho>
                        <TextoCabecalho>{nome}</TextoCabecalho>
                        <BotaoRemover />
                    </Cabecalho>
                    <TextoNumero style={{ alignSelf: "start" }}>*** *** *** *** {numero}</TextoNumero>
                    <Footer>
                        {cartaoPadrao ?
                            (
                                <BotaoCartaoPadrao>
                                    <TextoBotaoCartaoPadrao>
                                        Cartão Padrão
                                    </TextoBotaoCartaoPadrao>
                                </BotaoCartaoPadrao>
                            ) : (
                                <BotaoDefinirCartaoPadrao>
                                    <TextoBotaoDefinirCartaoPadrao onClick={onClickDefinirComoPadrao}>
                                        Definir como cartão padrão
                                    </TextoBotaoDefinirCartaoPadrao>
                                </BotaoDefinirCartaoPadrao>
                            )}
                    </Footer>
                </Container>
            ) :
                (
                    <Container onClick={trocarCartaoSelecionado}
                        style={{ background: theme.cores.neutral40, cursor: "pointer" }}>
                        <Cabecalho>
                            <TextoCabecalho>{nome}</TextoCabecalho>
                            {cartaoSelecionado ? (
                                <BotaoCartaoPadrao>
                                    <TextoBotaoCartaoPadrao>
                                        Cartão Selecionado
                                    </TextoBotaoCartaoPadrao>
                                </BotaoCartaoPadrao>
                            ) : (
                                <div style={{ display: "none" }}></div>
                            )}
                        </Cabecalho>
                        <TextoNumero>*** *** *** *** {numero}</TextoNumero>
                    </Container>
                )}
        </>
    );
}
