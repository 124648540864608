import styled from '@emotion/styled';
import { Switch } from '@mui/material';
import { BottomSheet } from 'react-spring-bottom-sheet';
import darkMode from '../../../assets/icons/dark_mode.svg';
import lightMode from '../../../assets/icons/wb_sunny.svg';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  position: relative;
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Background = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 70%;
  height: 100vh;
  overflow-y: scroll;

  @media (max-width: 1100px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 0%;
    visibility: hidden;
  }
`;

export const ContainerFormulario = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100vh;
  background-color: ${({ theme }) => theme.cores.surfaceBackground1};
  overflow-y: scroll;

  @media (max-width: 1100px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ImagemWaybe = styled.div`
  width: 65%;
  max-width: 350px;
  height: 10%;
  min-height: 66px;
  background-position: center;
  background-size: contain;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  display: flex;

  @media (max-width: 800px) {
    width: 75%;
    margin-bottom: 10px;
  }
`;

export const TextoTitulo = styled.h4`
  color: ${({ theme }) => theme.adaptativo('neutral10', 'neutral100')};
  font-family: 'comfortaa', sans-serif;
  font-size: 23px;
  font-weight: 500;
  line-height: 25px;

  @media (max-width: 800px) {
    margin: 10px 20px;
  }
`;

export const TextoAviso = styled.p`
  color: ${({ theme }) => theme.adaptativo('neutral10', 'neutral100')};
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  @media (max-width: 800px) {
    margin: 10px 20px;
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 15%;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 25px;
  margin-bottom: 6vh;

  @media (max-width: 1300px) {
    padding: 0px 10%;
  }

  @media (max-width: 800px) {
    padding: 0px 5%;
    margin-bottom: 8vh;
  }
`;

export const TextoEsqueciSenha = styled.p`
  color: ${({ theme }) => theme.cores.actionActive};
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 50px;

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

export const ContainerExpansivo = styled.div`
  flex-grow: 1;
`;

export const ContainerSifat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  min-height: 60px;
  background-color: ${({ theme }) =>
    theme.adaptativo('surfaceBackground1', 'surface2')};

  @media (max-width: 800px) {
    height: 80px;
  }
`;

export const LogoSifat = styled.img`
  width: 10vw;

  @media (max-width: 800px) {
    width: 130px;
  }
`;

export const ContainerModal = styled.div`
  background-color: ${({ theme }) => theme.cores.surface2};
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 24px;
  padding-top: 32px;

  h3 {
    color: ${({ theme }) => theme.adaptativo('neutral40', 'neutral100')};
    font-family: 'comfortaa', sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.15px;
  }

  p {
    color: ${({ theme }) => theme.adaptativo('neutral10', 'neutral100')};
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
    letter-spacing: 0.5px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const LinhaBotoes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 80px;
`;

export const BottomSheetCustomizada = styled(BottomSheet)`
  --rsbs-bg: ${({ theme }) => theme.cores.surface2};
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchEstilizado = styled(Switch)`
  margin: 0;
  > span {
    margin: 0;
  }

  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.cores.neutral100};
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      background-color: transparent;
      height: min-content;
      /* display: flex;
            align-items: center;
            justify-content: center; */
      margin-top: 3px;
      content: ${({ theme }) =>
        `url(${theme.temaDarkAtivo ? darkMode : lightMode})`};
    }
  }

  .MuiSwitch-track {
    background-color: ${({ theme }) =>
      theme.temaDarkAtivo
        ? theme.adaptativo('', 'actionDisable')
        : 'rgba(144, 205, 255, 1)'} !important;
  }

  &.MuiSwitch-root {
    width: 48px;
    height: 24px;
  }
`;
