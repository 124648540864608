import { Fragment, useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import Carregando from "../../pages/carregando";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../contexts/api";
import { getURLWaymenu } from "../../utils/retornarURLWaymenu";

export function RedirectWaymenu() {
  const { getDadosEmpresa } = useAuth();
  const navigate = useNavigate();
  let { tipo } = useParams();

  useEffect(() => {
    irParaCardapio();
  }, []);

  async function irParaCardapio() {
    let dadosEmpresaDTO = await getDadosEmpresa();
    let urlAmigavel = dadosEmpresaDTO?.urlAmigavel;
    if (urlAmigavel) {
      let urlPagina = window.location.href;
      let url = getURLWaymenu(urlPagina);
      if (!url) {
        toast.error("Falha ao obter a URL do Waymenu");
      } else {
        window.location.replace(`${url}/menu/${urlAmigavel}/${tipo}/`);
      }
    } else {
      toast.error("url_amigavel não configurada");
      navigate("/");
    }
  }

  return (
    <Fragment>
      <Carregando />
    </Fragment>
  );
}
