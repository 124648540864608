import {
  CabecalhoMudarPagina,
  ContainerFundoBranco,
  ItemPreenchido,
  TextoCabecalhoMudarPagina,
  TextoCorLinkNovoPlano,
} from "../styles";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import {
  CardFooter,
  Container,
  ContainerCards,
  ContainerItemCard,
  CorpoCard,
  TituloCard,
  Linha,
  ContainerCard,
  FooterCard,
  LinhaBotoesInformacoesGerais,
  Coluna,
  ContainerAviso,
  TextoAviso,
  NomeEmpresa,
} from "./styles";
import {
  BotaoAzulPreenchido,
  BotaoAzulVazado,
  BotaoVerdePreenchido,
  BotaoVermelhoPreenchido,
  BotaoVermelhoSemFundo,
  BotaoVermelhoVazado,
} from "../../../../components/Botao";
import { IndicadorStatus } from "../../../../components/IndicadorStatus";
import { TabelaLib } from "../../../../components/TabelaLib";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";
import { Fragment, useEffect, useRef, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ResponseInformacoesGerais } from "../../../../services/contrato/waybe/MapperInformacoesGerais";
import formatarReal from "../../../../utils/formatarReal";
import { useApi } from "../../../../contexts/api";
import { toast } from "react-toastify";
import { formatarDataParaPadraoBrasileiro } from "../../../../utils/formatarDataPadraoBrasileiro";
import { GerarModalConfirmacao } from "../../../../components/PopUpConfirmacao";
import { CampoDeTextoBase } from "../../../../components/CampoInput/styles";
import {
  CampoDeInputNumericoComSetas,
  CampoDeSelecao,
  CampoDeTexto,
} from "../../../../components/CampoInput";
import { CampoDeInputNumerico } from "../../../../components/CampoInputSemEstado";
import { getUrlEmpresa } from "../../../../enums/TipoEmpresa";
import Carregando from "../../../carregando";
import PlanosCompra from "../../listagemPlanos/planosCompra";
import RealizarPagamento from "../../../realizarPagamento";
import { Box, Chip, CircularProgress } from "@mui/material";
import GerarModalResponsivo from "../../../../components/GerarModalResponsivo";
import { Save } from "@mui/icons-material";
import { useAuth } from "../../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import tornarSlider from "../../../../utils/tornarSlider";
import useTamanhoTela from "../../../../utils/useTamanhoTela";

var aplicacaoSelecionadaGlobal;
export function InformacoesGerais({ detalharPlano }) {
  const PAGINA_INFORMACOES_GERAIS = "PAGINA_INFORMACOES_GERAIS";
  const PAGINA_PLANOS_DISPONIVEIS = "PAGINA_PLANOS_DISPONIVEIS";
  const PAGINA_PAGAMENTO = "PAGINA_PAGAMENTO";

  const refSlider = useRef();
  const tema = useTheme();
  const { get, put, post, del } = useApi();
  const navigate = useNavigate();
  const { pagamentoNecessario } = useAuth();

  const [paginaAtiva, setPaginaAtiva] = useState(PAGINA_INFORMACOES_GERAIS);
  const [dadosPagamento, setDadosPagamento] = useState();
  const [desabilitadoSessao, setDesabilitadoSessao] = useState(false);

  const [informacoes, setInformacoes] = useState({});
  const [dadosTabela, setDadosTabela] = useState();
  const [loading, setLoading] = useState(true);
  const [possuiContratosParaCancelar, setPossuiContratosParaCancelar] =
    useState(false);

  useEffect(() => {
    aplicacaoSelecionadaGlobal = undefined;
  }, []);

  useEffect(() => {
    if (paginaAtiva === PAGINA_INFORMACOES_GERAIS) {
      tornarSlider(refSlider.current);
      buscarDados();
    }
  }, [paginaAtiva]);

  async function buscarDados() {
    await get("/contrato/consumidor/waybe/empresa-contrato/informacoes-gerais")
      .then((response) => {
        if (response.status === 200) {
          let data = ResponseInformacoesGerais.map(response.data);
          setInformacoes(data);
          renderizarItems(data.listaContratos, data.idEmpresaContratoWaymenu);
          setLoading(false);
        } else {
          throw new Error("Erro ao obter o contrato");
        }
      })
      .catch((error) => {
        setLoading(false);
        setInformacoes([]);
        console.error(error);
        navigate("/");
        toast.error("Desculpe! Houve um erro ao obter o contrato");
      });
  }

  function Card({ children, titulo, footer }) {
    return (
      <CorpoCard>
        <ContainerCard style={{ marginBottom: "24px", fontSize: "23px" }}>
          {titulo}
        </ContainerCard>

        <ContainerCard style={{ flex: "1" }}>{children}</ContainerCard>

        <CardFooter>{footer}</CardFooter>
      </CorpoCard>
    );
  }

  function ItemCard({ titulo, conteudo, corConteudo }) {
    return (
      <ContainerItemCard>
        <p>{titulo}</p>
        <p style={{ fontWeight: "600", color: corConteudo }}>{conteudo}</p>
      </ContainerItemCard>
    );
  }

  function ItemCardMonetario({ titulo, conteudo, corConteudo }) {
    return (
      <ContainerItemCard>
        <p>{titulo}</p>
        <p style={{ fontWeight: "600", color: corConteudo }}>
          {formatarReal(conteudo)}
        </p>
      </ContainerItemCard>
    );
  }

  function tituloCardSessoesExtras(titulo) {
    const quantidadeSessoesExtrasCanceladas =
      informacoes.sessoesExtras.quantidadeSessoesExtrasCanceladas;

    if (quantidadeSessoesExtrasCanceladas > 0)
      return tituloCardFactory(
        titulo,
        "vermelho",
        quantidadeSessoesExtrasCanceladas + " Recorências canceladas"
      );

    return tituloCard(titulo);
  }

  function tituloCardFactory(titulo, cor, textoChip) {
    if (cor && textoChip) return tituloCardComTooltip(titulo, cor, textoChip);
    else return tituloCard(titulo);
  }

  function tituloCard(titulo) {
    return <TituloCard>{titulo}</TituloCard>;
  }

  function tituloCardComTooltip(titulo, cor, textoChip) {
    return (
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: "8px" }}
      >
        <TituloCard>{titulo}</TituloCard>
        <IndicadorStatus
          cor={cor}
          texto={textoChip}
          style={{ height: 28, maxWidth: 128 }}
        />
      </div>
    );
  }

  function footerCardSessao() {
    return (
      <FooterCard>
        <BotaoModalCancelarRecorrenciaExtras />
        <BotaoModalIncrementarExtras />
      </FooterCard>
    );
  }

  function irParaPagina() {}

  function buscarValoresColuna() {}

  function isPossuiContratoParaCancelar(data) {
    for (let index in data) {
      if (data[index].status.status === "PAGO") {
        return true;
      }
    }

    return false;
  }

  function renderizarItems(data, idEmpresaContratoWaymenu) {
    setPossuiContratosParaCancelar(isPossuiContratoParaCancelar(data));

    let dadosTabela = data.map((contrato, index) =>
      mapToContrato(contrato, index)
    );

    // // Não mostrar Linha Waymenu caso não contratado.
    // if (!idEmpresaContratoWaymenu || idEmpresaContratoWaymenu == undefined) {
    //   dadosTabela = dadosTabela.filter(
    //     (contrato) => contrato.aplicacao !== "Waymenu"
    //   );
    // }

    let novosDadosTabela = {
      mudarPagina: irParaPagina,
      valoresColuna: buscarValoresColuna,
      colunas: [
        { key: "aplicacao", titulo: "Meus contratos", esconder: false },
        { key: "plano", titulo: "Tipo de plano", esconder: false },
        { key: "sessao", titulo: "Quantidade de sessões", esconder: false },
        { key: "status", titulo: "Status" },
        { key: "acao", titulo: "Ações", ordenacao: false },
      ],
      gridColuna: `repeat(3, minmax(250px, 1fr)) 150px 80px !important`,
      nodes: dadosTabela,
    };

    setDadosTabela(novosDadosTabela);
  }

  function mapToContrato(contrato, index) {
    let desabilitado = contrato.status.status === "NAO_CONTRATADO";
    return {
      id: contrato.idContrato,
      aplicacao: contrato.nomeAplicacao,
      plano: gerarTipoPlano(contrato, desabilitado),
      sessao:
        contrato.quantidadeSessoes === 0 ? "-" : contrato.quantidadeSessoes,
      status: (
        <IndicadorStatus
          cor={contrato.status.cor}
          texto={contrato.status.descricao}
          style={{ height: 28 }}
        />
      ),
      acao: (
        <Linha>
          {gerarIconeOlho(desabilitado, contrato.tipoAplicacaoContrato)}
        </Linha>
      ),
    };
  }

  function gerarTipoPlano(contrato, desabilitado) {
    if (desabilitado)
      return (
        <Link
          key={contrato.tipoAplicacaoContrato}
          onClick={() => {
            aplicacaoSelecionadaGlobal = contrato.tipoAplicacaoContrato;
            irParaPlanosDisponiveis();
          }}
          style={{ textDecoration: "none" }}
        >
          <TextoCorLinkNovoPlano>
            <p>Contratar</p>
          </TextoCorLinkNovoPlano>
        </Link>
      );
    else return contrato.tipoPlano;
  }

  function gerarIconeOlho(desabilitado, tipoAplicacaoContrato) {
    if (desabilitado || pagamentoNecessario)
      return (
        <div>
          <RemoveRedEyeIcon style={{ fill: corAzulIconeOlhoDesabilitado() }} />
        </div>
      );
    else
      return (
        <Link to="" onClick={() => detalharPlano(tipoAplicacaoContrato)}>
          <RemoveRedEyeIcon style={{ fill: corAzulIconeOlhoHabilitado() }} />
        </Link>
      );
  }

  function corVerdeDinheiro() {
    return tema.getCorTema("#1EA834", tema.cores.semanticSuccess2);
  }

  function corAzulIconeOlhoHabilitado() {
    return tema.getCorTema("#006495", "#23A0E8");
  }

  function corAzulIconeOlhoDesabilitado() {
    return "#DCE3E9";
  }

  function formatarRecorrencia(periodicidade) {
    const mapaPeriodicidade = {
      MENSAL: "Mensal",
      TRIMESTRAL: "Trimestral",
      SEMESTRAL: "Semestral",
      ANUAL: "Anual",
    };

    return mapaPeriodicidade[periodicidade] || periodicidade;
  }

  function BotaoModalCancelarRecorrencias() {
    const [motivoCancelamento, setMotivoCancelamento] = useState("");

    function cancelarContratos(fecharModal) {
      if (pagamentoNecessario) return;
      post(
        "/contrato/consumidor/waybe/empresa-contrato/aplicacoes/cancelar-recorrencia",
        {
          motivoCancelamento,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            toast.info("Sucesso! Todos os seus contratos foram cancelados");
            buscarDados();
          } else {
            toast.error(
              "Desculpe! Houve um erro ao cancelar os seus contratos"
            );
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Desculpe! Houve um erro ao cancelar os seus contratos");
        })
        .finally(() => {
          fecharModal();
        });
    }

    return (
      <GerarModalConfirmacao
        onClick={(b) => cancelarContratos(b)}
        trigger={
          <BotaoVermelhoVazado texto="Cancelar Contratos" tamanho="SM" />
        }
        titulo="Cancelar Contratos"
        subTitulo="Deseja realmente cancelar todos os seus contratos?"
        observacao="OBS: Ao cancelar os seus contratos, eles ainda serão válidos até o vencimento, porém não serão mais renovados.  Não será possível reverter essa ação"
        botaoCancelar={(fechar) => (
          <BotaoVermelhoVazado texto="Não" tamanho="MD" onClick={fechar} />
        )}
        botaoConfirmar={(fechar) => (
          <BotaoVermelhoPreenchido
            texto="Sim"
            tamanho="MD"
            onClick={() => cancelarContratos(fechar)}
          />
        )}
        campoInput={
          <CampoDeTexto
            titulo="Motivo do cancelamento"
            value={motivoCancelamento}
            onChange={(e) => setMotivoCancelamento(e.target.value)}
          />
        }
      />
    );
  }

  function BotaoModalIncrementarExtras() {
    const { dadosAuth } = useAuth();
    const [quantidadeExtras, setQuantidadeExtras] = useState(1);
    const [valorTotal, setValorTotal] = useState(0);
    const [valorUnitario, setValorUnitario] = useState(0);
    const [carregando, setCarregando] = useState(true);
    const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);

    function atualizarValor() {
      if (pagamentoNecessario) return;
      setCarregando(true);
      get(
        "/contrato/consumidor/waybe/empresa-contrato/item/extra/calculo/sessao-extra/" +
          quantidadeExtras
      )
        .then((response) => {
          if (response.status === 200) {
            setValorTotal(response.data.listaItens[0].valorTotal);
            setValorUnitario(response.data.listaItens[0].valorUnitario);
          } else {
            setDesabilitadoSessao(true);
            setValorTotal(null);
            setValorUnitario(null);
          }
          setCarregando(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    useEffect(() => {
      atualizarValor();
    }, []);

    useEffect(() => {
      atualizarValor();
    }, [quantidadeExtras]);

    function incrementarExtras(fecharModal) {
      if (pagamentoNecessario) return;
      if (carregandoRequisicao) return;
      setCarregandoRequisicao(true);
      let itemExtra = {
        tipoItemPlano: "SESSAO_EXTRA",
        quantidade: quantidadeExtras,
      };

      post(
        "/contrato/consumidor/waybe/empresa-contrato/item/extra/por-tipo",
        itemExtra
      )
        .then((response) => {
          setCarregandoRequisicao(false);
          if (response.status === 200) {
            toast.info("Sucesso! Sessões foram contratadas com sucesso");
            buscarDados();
          } else {
            if (response.data.message === "Nenhum cartao padrao encontrado.") {
              toast.error(
                "Não foi possível concluir a ação, pois nenhum cartão está cadastrado. Por favor, cadastre um cartão e tente novamente."
              );
              return;
            }
            toast.error(
              "Desculpe! Houve um erro ao contratar as sessões extras"
            );
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Desculpe! Houve um erro ao contratar as sessões extras");
        })
        .finally(() => {
          setQuantidadeExtras(0);
          fecharModal();
        });
    }

    const styleDesabilitado = desabilitadoSessao
      ? {
          pointerEvent: "none",
          backgroundColor: tema.cores.actionDisable,
          color: tema.cores.textActionDisable,
          border: `1px solid ${tema.cores.actionDisable}`,
          cursor: "auto",
        }
      : {};

    return (
      <GerarModalConfirmacao
        disabled={desabilitadoSessao}
        trigger={
          <BotaoAzulVazado
            style={{
              ...{
                minWidth: "150px",
                padding: "0px 0px",
              },
              ...styleDesabilitado,
            }}
            texto="Contratar Sessões"
            tamanho="SM"
          />
        }
        titulo="Contratar sessões extras"
        subTitulo={
          <div style={{ marginLeft: 16, marginTop: "-26px" }}>
            <p style={{ fontSize: 12, lineHeight: "normal", marginBottom: 24 }}>
              O valor das sessões é calculado com base na quantidade de dias até
              o vencimento do contrato.
            </p>
            <p style={{ fontSize: 16, lineHeight: "normal", marginBottom: 24 }}>
              Valor Unitário (30 dias):
              <span
                style={{
                  // fontWeight: 600,
                  marginLeft: 4,
                  // color: corVerdeDinheiro(),
                }}
              >
                {formatarReal(valorUnitario)}
              </span>
            </p>
            Valor total:
            {carregando ? (
              <CircularProgress
                size={13}
                style={{ marginLeft: 8, fill: tema.cores.textActionSuccess }}
              />
            ) : (
              <span
                style={{
                  fontWeight: 600,
                  marginLeft: 4,
                  color: corVerdeDinheiro(),
                }}
              >
                {formatarReal(valorTotal)}
              </span>
            )}
          </div>
        }
        botaoCancelar={(fechar) => (
          <BotaoVermelhoVazado
            texto="Cancelar"
            tamanho="MD"
            disabled={desabilitadoSessao}
            onClick={fechar}
            style={{
              minWidth: "150px",
              padding: "0px 0px",
              userSelect: "none",
            }}
          />
        )}
        botaoConfirmar={(fechar) => (
          <BotaoVerdePreenchido
            style={{
              minWidth: "150px",
              padding: "0px 0px",
              userSelect: "none",
            }}
            texto="Contratar"
            tamanho="MD"
            onClick={() => incrementarExtras(fechar)}
            icone={
              carregandoRequisicao ? (
                <CircularProgress
                  style={{ color: "white", fill: "white" }}
                  size={13}
                />
              ) : null
            }
          />
        )}
        campoInput={
          <CampoDeInputNumericoComSetas
            width="100%"
            label="Quantidade"
            style={{ userSelect: "none" }}
            value={quantidadeExtras}
            onChange={(e) => {
              setQuantidadeExtras(e);
            }}
          />
        }
      />
    );
  }

  function BotaoModalConfigurarContrato({ recorrenciaAtual }) {
    const [periodoRecorrencia, setPeriodoRecorrencia] =
      useState(recorrenciaAtual);

    function salvarConfiguracao() {
      if (pagamentoNecessario) return;
      put("/pagamento/pagamento-configuracao/recorrencia/" + periodoRecorrencia)
        .then((response) => {
          if (response.status === 200) {
            toast.info("Período de recorrência alterado com sucesso.");
            buscarDados();
          } else {
            toast.error("Houve um Erro ao alterar o período de recorrência");
            throw new Error(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Houve um Erro ao alterar o período de recorrência");
        });
    }

    function ChipCustomizado({ valor, titulo }) {
      return (
        <Chip
          label={
            <Box
              sx={{
                color:
                  periodoRecorrencia === valor
                    ? "white"
                    : tema.cores.actionActive,
              }}
            >
              {titulo}
            </Box>
          }
          variant={periodoRecorrencia === valor ? "filled" : "outlined"}
          color="primary"
          onClick={() => setPeriodoRecorrencia(valor)}
        />
      );
    }

    return (
      <GerarModalResponsivo
        width={"500px"}
        trigger={<BotaoAzulVazado texto={"Configurar"} tamanho="SM" />}
        children={(fechar) =>
          pagamentoNecessario ? (
            <></>
          ) : (
            <Coluna>
              <p style={{ marginBottom: 16 }}>Período de recorrência</p>
              <Linha>
                <ChipCustomizado valor={"MENSAL"} titulo={"Mensal"} />

                <ChipCustomizado valor={"TRIMESTRAL"} titulo={"Trimestral"} />

                <ChipCustomizado valor={"SEMESTRAL"} titulo={"Semestral"} />

                <ChipCustomizado valor={"ANUAL"} titulo={"Anual"} />
              </Linha>
              <ContainerAviso>
                <FontAwesomeIcon
                  icon={faWarning}
                  style={{
                    width: 20,
                    height: 20,
                    color: tema.cores.semanticAlert3,
                  }}
                />
                <TextoAviso>
                  Alterações feitas nas configurações de recorrência serão
                  aplicadas a partir da próxima data de vencimento.
                </TextoAviso>
              </ContainerAviso>
              <Linha style={{ marginTop: "16px", justifyContent: "flex-end" }}>
                <BotaoAzulVazado
                  texto="Cancelar"
                  tamanho="SM"
                  onClick={() => fechar()}
                />
                <BotaoVerdePreenchido
                  texto="Salvar"
                  tamanho="SM"
                  onClick={() => salvarConfiguracao()}
                />
              </Linha>
            </Coluna>
          )
        }
      />
    );
  }

  function BotaoModalCancelarRecorrenciaExtras() {
    const [quantidadeExtras, setQuantidadeExtras] = useState(0);

    function cancelarRecorrenciasExtras(fecharModal) {
      if (pagamentoNecessario) return;
      let itemExtra = {
        tipoItemPlanoWaybe: "SESSAO_EXTRA",
        quantidadeRecorrenciaCancelada: quantidadeExtras,
      };

      del(
        "contrato/consumidor/waybe/empresa-contrato/item/extra/por-tipo",
        itemExtra
      )
        .then((response) => {
          if (response.status === 200) {
            toast.info("Sucesso! Recorrência foi cancelada com sucesso");
            buscarDados();
          } else {
            toast.error("Desculpe! Houve um erro ao cancelar a recorrência");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Desculpe! Houve um erro ao cancelar a recorrência");
        })
        .finally(() => {
          setQuantidadeExtras(0);
          fecharModal();
        });
    }

    const styleDesabilitado = desabilitadoSessao
      ? {
          pointerEvent: "none",
          // backgroundColor: tema.cores.actionDisable,
          color: tema.cores.textActionDisable,
          // border: `1px solid ${tema.cores.actionDisable}`,
          cursor: "auto",
        }
      : {};

    return (
      <GerarModalConfirmacao
        disabled={desabilitadoSessao}
        trigger={
          <BotaoVermelhoSemFundo
            texto="Cancelar Sessões"
            tamanho="SM"
            style={{
              ...{ minWidth: "150px", padding: "0px 0px" },
              ...styleDesabilitado,
            }}
          />
        }
        titulo="Cancelar recorrência das sessões extras"
        botaoCancelar={(fechar) => (
          <BotaoVermelhoVazado texto="Cancelar" tamanho="MD" onClick={fechar} />
        )}
        botaoConfirmar={(fechar) => (
          <BotaoVermelhoPreenchido
            texto="Confirmar"
            tamanho="MD"
            onClick={() => cancelarRecorrenciasExtras(fechar)}
          />
        )}
        campoInput={
          <CampoDeInputNumericoComSetas
            width="100%"
            label="Quantidade"
            value={quantidadeExtras}
            onChange={(e) => setQuantidadeExtras(e)}
          />
        }
      />
    );
  }

  function PaginaInformacoesGerais() {
    let { largura, altura } = useTamanhoTela();

    useEffect(() => {
      tornarSlider(refSlider.current);
    }, [largura, altura]);

    return loading ? (
      <Carregando />
    ) : (
      <div style={{ pointerEvents: pagamentoNecessario ? "all" : "all" }}>
        <NomeEmpresa>{`Integrador: ${
          informacoes?.integrador ? informacoes?.integrador : "Não encontrado"
        }`}</NomeEmpresa>
        <ContainerCards ref={refSlider}>
          <Card
            titulo={tituloCardFactory("Informações gerais")}
            footer={
              <LinhaBotoesInformacoesGerais>
                <BotaoModalConfigurarContrato
                  recorrenciaAtual={informacoes.recorrencia}
                />
                {possuiContratosParaCancelar && (
                  <BotaoModalCancelarRecorrencias />
                )}
              </LinhaBotoesInformacoesGerais>
            }
          >
            <ItemCard
              titulo="Inicio de Contrato:"
              conteudo={formatarDataParaPadraoBrasileiro(
                informacoes.dataInicioContrato
              )}
            />
            <ItemCard
              titulo="Vencimento de Contrato:"
              conteudo={formatarDataParaPadraoBrasileiro(
                informacoes.dataVencimentoContrato
              )}
            />
            <ItemCard
              titulo="Recorrência:"
              conteudo={formatarRecorrencia(informacoes.recorrencia)}
            />
          </Card>

          <Card
            titulo={tituloCardFactory(
              "Pagamento",
              informacoes.statusPagamento.cor,
              informacoes.statusPagamento.descricao
            )}
          >
            {informacoes.tipoFaturamentoPlataforma != "BOLETO_BANCARIO" ? (
              <Fragment>
                <ItemCardMonetario
                  titulo="Valor total:"
                  conteudo={informacoes.valorTotal}
                  corConteudo={corVerdeDinheiro()}
                />
                <ItemCardMonetario
                  titulo="Valor extras:"
                  conteudo={informacoes.valorExtras}
                  corConteudo={corVerdeDinheiro()}
                />
              </Fragment>
            ) : (
              <></>
            )}
          </Card>

          <Card
            titulo={tituloCardSessoesExtras("Sessão")}
            footer={footerCardSessao()}
          >
            <ItemCard
              titulo="Sessões:"
              conteudo={informacoes.sessoesExtras.quantidadeSessoesPlano}
            />
            <ItemCard
              titulo="Sessões extras:"
              conteudo={informacoes.sessoesExtras.quantidadeSessoesExtras}
            />
            <ItemCard
              titulo="Total:"
              conteudo={informacoes.sessoesExtras.total}
            />
          </Card>
        </ContainerCards>

        <div style={{ marginTop: "44px" }}>
          <TabelaLib
            dadosTabela={dadosTabela}
            variante={{ ordenacao: false }}
          />
        </div>
      </div>
    );
  }

  function irParaInformacoesGerais() {
    setPaginaAtiva(PAGINA_INFORMACOES_GERAIS);
  }

  function PaginaPagamento({ dadosPagamento }) {
    return (
      <RealizarPagamento
        titulo={dadosPagamento.titulo}
        listaTotal={dadosPagamento.listaTotal}
        listaDetalhes={dadosPagamento.listaDetalhes}
        avisoAntesPagamento={dadosPagamento.avisoAntesPagamento}
        realizarPagamentoFuncao={dadosPagamento.realizarPagamentoFuncao}
        voltarParaTelaAnterior={irParaPlanosDisponiveis}
      />
    );
  }

  function irParaPaginaPagamento(dadosPagamento) {
    setDadosPagamento(dadosPagamento);
    setPaginaAtiva(PAGINA_PAGAMENTO);
  }

  function PlanosDisponiveis() {
    return (
      <PlanosCompra
        comparacao={false}
        voltar={irParaInformacoesGerais}
        irParaPagamento={irParaPaginaPagamento}
        modoReduzido={true}
        aplicacaoSelecionada={aplicacaoSelecionadaGlobal}
      />
    );
  }

  function irParaPlanosDisponiveis() {
    setPaginaAtiva(PAGINA_PLANOS_DISPONIVEIS);
  }

  function getPaginaAtiva() {
    switch (paginaAtiva) {
      case PAGINA_PAGAMENTO:
        return <PaginaPagamento dadosPagamento={dadosPagamento} />;
      case PAGINA_PLANOS_DISPONIVEIS:
        return <PlanosDisponiveis />;
      case PAGINA_INFORMACOES_GERAIS:
        return <PaginaInformacoesGerais />;
    }
  }

  return getPaginaAtiva();
}
