import { styled } from '@mui/material/styles';
import { Switch as MaterialSwitch } from '@mui/material/';
import { useTheme } from '@emotion/react';
import { FormControlLabelCustomizado } from './styles';
import isPropValid from '@emotion/is-prop-valid';
import darkMode from '../../assets/icons/dark_mode.svg';
import lightMode from '../../assets/icons/wb_sunny.svg';

const definirTamanho = (tamanho) => {
  switch (tamanho) {
    case 'SM':
      return { largura: 32, altura: 16 };
    case 'MD':
      return { largura: 48, altura: 24 };
    case 'LG':
      return { largura: 65, altura: 32 };
    default:
      return { largura: 48, altura: 24 };
  }
};

const SwitchCustomizado = styled(MaterialSwitch, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== '$valor',
})(({ theme, tamanho, $valor, mudarTema }) => ({
  width: tamanho.largura,
  height: tamanho.altura,
  padding: 0,
  display: 'flex',
  marginRight: 8,
  '& .MuiSwitch-switchBase': {
    padding: '3.5%',
  },
  '& .Mui-checked': {
    transform: `translateX(${tamanho.largura / 2}px)`,
    color: '#fff',
    '& + .MuiSwitch-track': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.cores.brand10
          : theme.palette.cores.brand60,
      opacity: 1,
      border: 0,
    },
  },
  '& .MuiSwitch-thumb': {
    width: tamanho.altura / 1.1,
    height: tamanho.altura / 1.2,
    borderRadius: 60,
    backgroundColor:
      theme.palette.mode === 'light'
        ? $valor === true
          ? theme.palette.cores.actionPressed
          : theme.palette.cores.neutral70
        : $valor === true
          ? theme.palette.cores.actionPressed
          : theme.palette.cores.actionDisable,
  },
  '& .MuiSwitch-track': {
    borderRadius: 50,
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(220, 227, 233, 1)'
        : theme.palette.cores.neutral70,
    boxSizing: 'border-box',
    opacity: 1,
  },
  ...(mudarTema && {
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.cores.neutral100,
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before': {
        backgroundColor: 'transparent',
        height: 'min-content',
        marginTop: 3,
        content: `url(${theme.temaDarkAtivo ? darkMode : lightMode})`,
      },
    },
  }),
}));

export const Switch = ({
  texto,
  tamanho,
  valor,
  onClick,
  style,
  styleSwitch,
  mudarTema = false,
  ...rest
}) => {
  return (
    <FormControlLabelCustomizado
      style={{ ...style }}
      tamanho={definirTamanho(tamanho)}
      checked={valor}
      onChange={onClick}
      control={
        <SwitchCustomizado
          $valor={valor}
          checked={valor}
          onChange={onClick}
          tamanho={definirTamanho(tamanho)}
          style={{ ...styleSwitch }}
          mudarTema={mudarTema}
        />
      }
      label={texto}
      labelPlacement="end"
      {...rest}
    />
  );
};

export default Switch;
